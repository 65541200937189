<template>
    <div>
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <div class="tab-container mb-5">
            <ul role="tablist" class="nav nav-tabs">
                <li class="nav-item" v-for="(item, index) in sets">
                    <a href="#" @click.prevent="setcurrentSet(item)" class="nav-link" v-bind:class="{'active': currentSet === item}">{{ segment }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="addSet"><i class="s7-plus"></i></a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="home" role="tabpanel" class="tab-pane active">
                    <slot :id="currentSet"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            title : {
                type : String,
                required : false,
                default : ''
            },
            description: {
                type : String,
                required : false,
                default : ''
            },
            segment: {
                type : String,
                required : false,
                default : 'Sectie'
            },
        },
        data: function(){
            return {
                sets : [0],
                setCounter : 1,
                currentSet : 0,
            }
        },
        methods : {
            setcurrentSet(id){
                this.currentSet = id;
            },

            addSet(){
                this.sets.push(this.setCounter)
                this.currentSet = this.setCounter
                this.setCounter += 1
            }
        }
    }
</script>

<style>

</style>