<template>
    <div class="row">
        <div class="col-md-4">
            <approve-box v-if="user.role.id === 4" :user="user.id"></approve-box>
            <div class="user-info-list panel panel-default panel-border-color panel-border-color-success">
                <div class="panel-heading panel-heading-divider">
                    <div class="tools">
                        <router-link class="icon" :to="{ name: 'users.edit', params: { userId: user.id  }}"><i class="s7-pen"></i></router-link>
                        <router-link class="icon" :to="{ name: 'users.delete', params: { userId: user.id }}"><i class="s7-trash"></i></router-link>
                    </div>
                    <strong>{{ user.firstname }} {{ user.lastname }}</strong></div>
                <div class="panel-body">
                    <table class="no-border no-strip skills">
                        <tbody class="no-border-x no-border-y">
                        <tr>
                            <td class="icon"><span class="icon s7-map-marker"></span></td>
                            <td class="item">Adres</td>
                            <td class="text-danger">{{ user.street }}, {{ user.zipcode }} {{ user.city }}</td>
                        </tr>
                        <tr>
                            <td class="icon"><span class="icon s7-mail"></span></td>
                            <td class="item">Email</td>
                            <td class="text-danger">{{ user.email }}</td>
                        </tr>
                        <tr>
                            <td class="icon"><span class="icon s7-phone"></span></td>
                            <td class="item">Telefoon</td>
                            <td class="text-danger">{{ user.phone }}</td>
                        </tr>
                        <tr>
                            <td class="icon"><span class="icon s7-wallet"></span></td>
                            <td class="item">Credits</td>
                            <td class="text-danger">{{ user.credits }}</td>
                        </tr>
                        <tr>
                            <td class="icon"><span class="icon s7-door-lock"></span></td>
                            <td class="item">Rol</td>
                            <td class="text-danger">{{ user.role.name }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="widget widget-tile widget-tile-wide">
                <div class="tile-info">
                    <div class="icon"><span class="s7-notebook"></span></div>
                    <div class="data-info">
                        <div class="title">Leesgroepen</div>
                    </div>
                </div>
                <div class="tile-value"><span>{{ user.groups.length }}</span></div>
            </div>
            <div class="widget widget-tile widget-tile-wide">
                <div class="tile-info">
                    <div class="icon"><span class="s7-culture"></span></div>
                    <div class="data-info">
                        <div class="title">Werken</div>
                    </div>
                </div>
                <div class="tile-value"><span>{{ user.works.length }}</span></div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel panel-border-color panel-border-color-success">
                <div class="panel-heading panel-heading-divider">Formulier</div>
                <div class="panel-body">
                    <div v-if="user.form !== null" class="tab-container">
                        <ul role="tablist" class="nav nav-pills">
                            <li class="nav-item"><a href="#begeleiding" data-toggle="tab" role="tab" class="nav-link active">Begeleiding</a></li>
                            <li class="nav-item"><a href="#praktijk" data-toggle="tab" role="tab" class="nav-link">Praktijk</a></li>
                            <li class="nav-item"><a href="#opleiding" data-toggle="tab" role="tab" class="nav-link">Opleiding</a></li>
                            <li class="nav-item"><a href="#persoonlijk" data-toggle="tab" role="tab" class="nav-link">Persoonlijk</a></li>
                            <li class="nav-item"><a href="#interesses" data-toggle="tab" role="tab" class="nav-link">Interesses</a></li>
                            <li class="nav-item"><a href="#missie" data-toggle="tab" role="tab" class="nav-link">Missie</a></li>
                        </ul>
                        <div class="tab-content">
                            <div id="begeleiding" role="tabpanel" class="tab-pane active">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Leesbegeleiding</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p v-if="user.form.when === 'binnen werkuren'">Binnen De Werkuren</p>
                                        <p v-else>Als Vrijwilliger</p>
                                    </div>
                                </div>
                            </div>
                            <div id="praktijk" role="tabpanel" class="tab-pane">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Frequentie van begleiding</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.frequency }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Verwacht aantal leesbijeenkosmten/jaar</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.reading_days_frequency }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Ben je verzekerd door de organisatie waarvoor je leest?</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.is_insured ? 'Ja' : 'Neen' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div id="opleiding" role="tabpanel" class="tab-pane">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Wanneer verkies je opleidingsdagen?</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p v-if="user.form.education_days_preference === 'weekend'">Tijdens het weekend</p>
                                        <p v-else>Tijdens de week</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Wanneer verkies je andere bijeenkomsten?</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p v-if="user.form.reading_days_preference === 'weekend'">Tijdens het weekend</p>
                                        <p v-else>Tijdens de week</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Reeds gevolgde opleiding(en)</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.followed_educations }}</p>
                                    </div>
                                </div>
                            </div>
                            <div id="persoonlijk" role="tabpanel" class="tab-pane">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Een hart hebben voor verhalen</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.story_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Een hart hebben voor poezie</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.poetry_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Een hart hebben voor mensen</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.people_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Goed kunnen luisteren</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.listning_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Eigen inzichten en overtuigingen kunnen bijstellen</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.open_minded_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>In staat zijn om groepsprocessen te begeleiden</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.supervising_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Respect tonen voor de deelnemer en de noden van de groep</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.respect_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Zelfstandig en zelfredzaam kunnen werken</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.independent_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Goed kunnen communiceren</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.communication_skill | formatSkill }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Planningen, afspraken en problemen correct opvolgen</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.planning_skill | formatSkill }}</p>
                                    </div>
                                </div>
                            </div>
                            <div id="interesses" role="tabpanel" class="tab-pane">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Schrijf je graag eens over je leesplek/ervaring?</strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.litterateur }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Maak je graag foto's? </strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.photographer }}</p>
                                    </div>
                                </div>
                            </div>
                            <div id="missie" role="tabpanel" class="tab-pane">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><strong>Wat spreekt jou zo aan bij het Lezerscollectief? </strong></p>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{ user.form.appeal_to_us }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        Geen formulier aanwzig
                    </div>
                </div>
            </div>

            <div class="panel panel-default" v-if="user.groups.length > 0">
                <div class="panel-heading panel-heading-divider">Leesgroepen</div>
                <div class="panel-body">
                    <p v-for="group in user.groups" :key="group.id"><router-link
                            :to="{name: 'groups.show', params : {groupId:  group.id}}"
                    >{{ group.name }}</router-link></p>
                </div>
            </div>

            <div class="panel panel-default" v-if="user.works.length > 0">
                <div class="panel-heading panel-heading-divider">Werken</div>
                <div class="panel-body">
                    <p v-for="work in user.works" :key="work.id"><router-link
                            :to="{name: 'works.show', params : {workId:  work.id}}"
                    >{{ work.name }}</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApproveBoxComponent from './ApproveBoxComponent.vue'

    export default {
        components : {
            'approve-box' : ApproveBoxComponent
        },
        data() {
            return {
                user : {
                    id: 0,
                    firstname : '',
                    lastname : '',
                    email: '',
                    street: '',
                    zipcode: '',
                    city: '',
                    phone: '',
                    credits: 0,
                    usedCredits: 0,
                    role : {
                        id : 0,
                        name : ''
                    },
                    works : [],
                    groups : [],
                    form: {}
                }
            }
        },
        methods : {
            loadUser : function(id){
                axios.get('/users/'+ id)
                    .then(response => {
                        this.user = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    });
            }
        },
        filters : {
            formatSkill : function(value){
                if(value === 1){
                    return 'Zwak'
                }else if(value === 2){
                    return 'Eerder Zwak'
                }else if(value === 3){
                    return 'Gemiddeld'
                }else if(value === 4){
                    return 'Eerder Sterk'
                }else if(value === 5){
                    return 'Sterk'
                }else{
                    return 'Onbekend'
                }
            }
        },
        props: {
            userId : {}
        },
        watch : {
            userId : function(newUser, oldUser){
                this.loadUser(newUser)
            }
        },
        created(){
            this.loadUser(this.userId)
        }
    }
</script>

<style>

</style>