<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-heading-divider panel-heading-full-width"><span class="title">Participant {{ config.submitText }}</span></div>
        <div class="panel-body">
            <former :config="config" ref="form" @form-success="formSuccess">
                <div class="row">
                    <div class="col-md-9">
                        <field-string title="Voornaam" name="name"></field-string>
                    </div>
                    <div class="col-md-3">
                        <field-select title="Geslacht" name="gender" :options="{'Man': 'Man', 'Vrouw': 'Vrouw'}"></field-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <field-select title="Leeftijd" name="age" :options="ageOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Thuistaal" name="language" :options="languageOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Arbeidsstatus" name="employment_status" :options="employmentOptions"></field-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5>Eerste Ervaring</h5>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Taalvaardigheid" name="language_proficiency" :options="skillOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Activiteit" name="activities" :options="skillOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Participatie" name="participation" :options="skillOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Beleving Samen Lezen" name="reading_together" :options="skillOptions"></field-select>
                    </div>
                    <div class="col-md-4">
                        <field-select title="Beleving Leesgroep" name="experience_reading_group" :options="skillOptions"></field-select>
                    </div>
                </div>
            </former>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {

                },
                skillOptions : {
                    1: '1 - Zwak',
                    2: '2 - Eeder Zwak',
                    3: '3 - Gemiddeld',
                    4: '4 - Eerder Goed',
                    5: '5 - Goed',
                },
                ageOptions : {
                    '5-14': '5-14',
                    '15-24': '15-24',
                    '25-34': '25-34',
                    '35-44': '35-44',
                    '45-54': '45-54',
                    '55-64': '55-64',
                    '65-74': '65-74',
                    '75-84': '75-84',
                    '85+': '85+',
                    'Niet geweten': 'Weet ik niet'
                },
                languageOptions : {
                    'Nederlands' : 'Nederlands',
                    'Frans': 'Frans',
                    'Duits': 'Duits',
                    'Engels': 'Engels',
                    'Spaans': 'Spaans',
                    'Italiaans': 'Italiaans',
                    'Turks': 'Turks',
                    'Syrisch': 'Syrisch',
                    'Chinees': 'Chinees',
                    'Malinees': 'Malinees',
                    'Marokkaans': 'Marokkaans',
                    'Niet-Nederlands': 'Niet-Nederlands',
                    'Portugees': 'Portugees',
                    'Weet ik niet': 'Weet ik niet'
                },
                employmentOptions: {
                    'Werkend': 'Werkend',
                    'Niet-werkend': 'Niet-werkend',
                    'Student': 'Student',
                    'Weet ik niet': 'Weet ik niet'
                }
            }
        },
        created() {
            if(this.$route.name === 'participants.create'){
                this.config = {
                    submitText: 'Toevoegen',
                    messageSuccessTitle: 'Participant Toegevoegd!',
                    storeUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/participants',
                    update: false
                }
            }else if(this.$route.name === 'participants.edit'){
                this.config = {
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Participant Gewijzigd!',
                    dataUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/participants/' + this.participantId,
                    updateUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/participants/' + this.participantId,
                    update: true
                }
            }else{
                this.$notifier.error('Ongekende route!')
            }
        },
        watch: {
            '$route' (to, from) {
                this.$refs.form.reset();
            }
        },
        methods : {
            formSuccess : function(response){
                this.$router.push({ name: 'participants', params: { groupId: this.groupId, periodId: this.periodId }})
            }
        },
        props : {
            groupId : {
                required : true,
            },
            periodId : {
                required : true,
            },
            participantId : {
                required : false,
            },
        }
    }
</script>

<style>

</style>