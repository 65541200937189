<template>
    <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
            <li class="paginate_button page-item previous" :class="{'disabled': isOnFirstPage}">
                <a class="page-link" @click.prevent="loadPage('prev')">Vorige</a>
            </li>

            <template v-if="notEnoughPages">
                <li v-for="n in totalPage" class="paginate_button page-item" :class="{'active': isCurrentPage(n)}">
                    <a class="page-link" @click.prevent="loadPage(n)" v-html="n"></a>
                </li>
            </template>
            <template v-else>
                <li v-for="n in windowSize" class="paginate_button page-item" :class="{'active': isCurrentPage(windowStart+n-1)}">
                    <a class="page-link" @click.prevent="loadPage(windowStart+n-1)" v-html="windowStart+n-1"></a>
                </li>
            </template>

            <li class="paginate_button page-item next" :class="{'disabled': isOnLastPage}">
                <a class="page-link" @click.prevent="loadPage('next')">Volgende</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {VuetablePaginationMixin} from 'vuetable-2'
    export default {
        mixins: [VuetablePaginationMixin]
    }
</script>