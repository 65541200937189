<template>
    <div>
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <div class="tab-container mb-5">
            <ul role="tablist" class="nav nav-tabs">
                <li class="nav-item" v-for="(tab, index) in tabs">
                    <a href="#" @click.prevent="setTab(index)" class="nav-link" v-bind:class="{'active': tab.isActive}">{{ tab.title }}</a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="home" role="tabpanel" class="tab-pane active">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function(){
            return {
                tabs : []
            }
        },
        props : {
            title : {
                type : String,
                required : false,
                default : ''
            },
            description: {
                type : String,
                required : false,
                default : ''
            }
        },
        methods : {
            setTab(index){
                for(var i = 0;i < this.tabs.length;i++){
                    this.tabs[i].isActive = (i === index)
                }
            }
        },
        created() {
            this.tabs = this.$children;
        },
        mounted() {
            this.tabs[0].isActive = true
        }
    }
</script>

<style>

</style>