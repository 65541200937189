<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="group-header">
                    <div class="group-box">
                        <div class="group-box-info">
                            <span class="group-box-name">{{ group.name }}</span>
                            <span class="group-box-address"><a href=""><i class="icon s7-map-marker"></i> </a> {{ group.street }}, {{ group.zipcode }} {{ group.city }}</span>
                            <span class="group-box-address"><a href=""><i class="icon s7-ticket"></i> </a> {{ group.category.name }}</span>
                        </div>
                        <div class="group-box-stats">
                            <div class="group-box-stat">
                                <span class="group-box-stat-counter">{{ counts.days }}</span>
                                <span class="group-box-stat-title" v-if="counts.days === 1">Leesdag</span>
                                <span class="group-box-stat-title" v-else>Leesdagen</span>
                            </div>
                            <div class="group-box-stat">
                                <span class="group-box-stat-counter">{{ counts.participants }}</span>
                                <span class="group-box-stat-title" v-if="counts.participants === 1">Participant</span>
                                <span class="group-box-stat-title" v-else>Participanten</span>
                            </div>
                            <div class="group-box-stat">
                                <span class="group-box-stat-counter">{{ counts.experiences }}</span>
                                <span class="group-box-stat-title" v-if="counts.experiences === 1">Ervaring</span>
                                <span class="group-box-stat-title" v-else>Ervaringen</span>
                            </div>
                            <div class="group-box-stat">
                                <span class="group-box-stat-counter">{{ group.periods.length }}</span>
                                <span class="group-box-stat-title" v-if="group.periods.length === 1">Periode</span>
                                <span class="group-box-stat-title" v-else>Periodes</span>
                            </div>
                        </div>
                    </div>
                    <div class="group-menu">
                        <ul>
                            <li><router-link :to="{ name: 'groups.show', params: { periodId: period.id }}">Overzicht</router-link></li>
                            <li><router-link :to="{ name: 'participants', params: { periodId: period.id }}">Participanten</router-link></li>
                            <li><router-link :to="{ name: 'days', params: { periodId: period.id }}">Leesdagen</router-link></li>
                            <li><router-link :to="{ name: 'groups.edit', params: {groupId: groupId }}">Voorkeuren</router-link></li>
                            <li><router-link :to="{ name: 'periods', params: {groupId: groupId }}" v-html="periodName"></router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                group: {
                    name : null,
                    street : null,
                    zipcode : null,
                    city: null,
                    category : {
                        name : null
                    },
                    periods : []
                },
                period : {
                    id: 0,
                },
                counts : {
                    days : 0,
                    participants : 0,
                    experiences : 0,
                },
                means : {
                    language_proficiency : 0.0,
                    activities : 0.0,
                    participation : 0.0,
                    reading_together : 0.0,
                    experience_reading_group : 0.0
                }
            }
        },
        props : {
            groupId : {
                required : true
            },
            periodId : {
                required: false
            }
        },
        created(){
            this.switchPeriod(this.periodId)
        },
        computed : {
            periodName : function(){
                if(this.period.id === 0){
                    return 'Periode';
                }

                let start = moment.unix(this.period.start).locale('nl')

                if(this.period.end === null){
                    return 'Periode ' + start.format('D MMM YYYY') + ' tot &infin;'
                }

                let end = moment.unix(this.period.end).locale('nl')

                if(end.year === start.year){
                    return 'Periode ' + start.format('D MMM') + ' - ' + end.format('D MMM') + ' ' + end.format('YYYY')
                }else{
                    return 'Periode ' + start.format('D MMM YYYY') + ' - ' + end.format('D MMM YYYY')
                }

            }
        },
        methods : {
            switchPeriod : function(id){
                // get information about the group
                axios.get('/groups/' + this.groupId)
                    .then(response => {
                        this.group = response.data.data

                        // get information about the period

                        if(id === undefined || id === 0 || id === "0"){
                            let sortedPeriods = this.group.periods.sort(function(a, b){
                                return a.start - b.start
                            })

                            id = sortedPeriods[sortedPeriods.length - 1].id
                        }

                        id = parseInt(id)

                        this.period = this.group.periods.find(function(period){
                            return period.id === id
                        })

                        axios.get('/groups/' + this.groupId + '/periods/' + this.period.id + '/counts')
                            .then(response => {
                                this.counts = response.data.counts
                                this.counts.days = response.data.counts.days
                                this.means = response.data.means
                            })
                            .catch(error => {
                                this.$notifier.error()
                            });

                    })
                    .catch(error => {
                        console.log(error)
                        this.$notifier.error()
                    });
            },
        }
    }
</script>

<style>

</style>