<template>
    <nav class="navbar navbar-expand navbar-dark mai-top-header">
        <div class="container">
            <a href="#" class="navbar-brand"></a>
            <!--Left Menu-->
            <ul class="nav navbar-nav mai-top-nav">
                <li class="nav-item">
                    <a style="padding-left: 3px!important" href="https://lezerscollectief.be" class="nav-link">Website</a>
                </li>
            </ul>
            <!--Icons Menu-->
            <ul class="navbar-nav float-lg-right mai-icons-nav">
                <slot name="dropdowns"></slot>
            </ul>
            <!--User Menu-->
            <ul class="nav navbar-nav float-lg-right mai-user-nav">
                <li class="dropdown nav-item">
                    <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="dropdown-toggle nav-link">
                        <span>{{ username }}</span>
                        <span class="angle-down s7-angle-down"></span>
                    </a>
                    <div role="menu" class="dropdown-menu">
                        <slot name="menu"></slot>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        props : {
            username : {
                required : true,
                type: String
            }
        }
    }
</script>

<style>
    .navbar-brand{
        margin-right: 0px!important;
    }

</style>