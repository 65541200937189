<template>
    <div>
        <div class="panel panel-default panel-table">
            <div class="panel-body">
                <div class="filters">
                    <div class="filters-title">
                        Leesdag {{ day.date | formatDate }}
                    </div>
                </div>
                <show-at breakpoint="small">
                    <chart-experiences :globalMeans="globalMeans" :experiences="day.experiences"></chart-experiences>
                </show-at>
                <show-at breakpoint="mediumAndAbove">
                    <chart-experiences :height="80" :globalMeans="globalMeans" :experiences="day.experiences"></chart-experiences>
                </show-at>
            </div>
        </div>
        <div class="panel panel-default panel-table">
            <div class="panel-body">
                <div class="table-responsive">
                    <table class="table table-striped table-borderless">
                        <thead>
                        <tr>
                            <th>Voornaam</th>
                            <th width="15%">Taalvaardigheid</th>
                            <th width="15%">Activiteiten</th>
                            <th width="15%">Participatie</th>
                            <th width="15%">Beleving Samen Lezen</th>
                            <th width="15%">Beleving Leesgroep</th>
                            <th>Aanwezig</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="experience in day.experiences">
                            <td>{{ experience.participant.name }}</td>
                            <td><experience-slider :disabled="experience.is_present" v-model="experience.language_proficiency"></experience-slider></td>
                            <td><experience-slider :disabled="experience.is_present" v-model="experience.activities"></experience-slider></td>
                            <td><experience-slider :disabled="experience.is_present" v-model="experience.participation"></experience-slider></td>
                            <td><experience-slider :disabled="experience.is_present" v-model="experience.reading_together"></experience-slider></td>
                            <td><experience-slider :disabled="experience.is_present" v-model="experience.experience_reading_group"></experience-slider></td>
                            <td>
                                <div class="switch-button switch-button-success">
                                    <input type="checkbox" v-model="experience.is_present" checked="" :name="experience.id" :id="experience.id">
                                    <span>
                                    <label :for="experience.id"></label>
                                </span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div class="filters">
                    <button style="margin-bottom: 40px;" class="btn btn-success" @click="saveChanges">Wijzigingen Opslaan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import ExperienceSlider from '../../components/ExperienceSliderComponent.vue'
    import ExperiencesChart from './charts/ExperiencesChartComponent.vue'
    import {showAt} from 'vue-breakpoints'

    export default {
        components : {
            'chart-experiences' : ExperiencesChart,
            'experience-slider' : ExperienceSlider,
            showAt
        },
        data() {
            return {
                day : {
                    date : null,
                    experiences : []
                }
            }
        },
        props : {
            groupId : {
                required : true,
            },
            periodId : {
                required: true
            },
            dayId : {
                required: true
            }
        },
        filters: {
            formatDate: function (value) {
                let fmt = 'D MMMM YYYY'
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            }
        },
        computed : {
            globalMeans : function(){
                return this.$parent.means;
            }
        },
        methods : {
            saveChanges : function(){
                axios.put('/experiences/day/' + this.dayId, {data: this.day.experiences})
                    .then(response => {
                        this.$notifier.success('Ervaringen Opgeslagen!');
                        this.$router.push({ name: 'days', params: { groupId: this.groupId, periodId: this.periodId }})
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            }
        },
        created(){
            axios.get('/groups/' + this.groupId + '/periods/' + this.periodId + '/days/' + this.dayId)
                .then(response => {
                    this.day = response.data.data
                })
                .catch(error => {
                    this.$notifier.error()
                })
        }
    }
</script>
