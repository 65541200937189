<template>
    <li class="nav-item parent" :class="{'open' : activated}">
        <a href="#" role="button" aria-expanded="false" class="nav-link" @click="setActive">
            <span :class="iconClass"></span>
            <span>{{ name }}</span>
        </a>
        <ul class="mai-nav-tabs-sub mai-sub-nav nav">
            <slot></slot>
        </ul>
    </li>
</template>

<script>
    export default {
        data() {
          return {
            activated : false,
          }
        },
        props : {
            name : {
                type: String,
                required : true,
            },
            icon : {
                type: String,
                required : true,
            },
            route : {
                type: String,
                required : true,
            },
            active : {
                type: Boolean,
                required: false,
                default : false,
            }
        },
        computed : {
            iconClass : function(){
                return 'icon s7-' + this.icon
            }
        },
        created(){
            this.activated = this.active
        },
        methods : {
            setActive : function(){
                this.$parent.$children.forEach(function(menuGroup){
                   menuGroup.activated = false
                });

                this.activated = true

                // Open first page
                if(this.$children.length > 0){
                    this.$children[0].setActive()
                }
            }
        }
    }
</script>

<style>

</style>