<template>
    <a :class="elementClass" class="" @click.prevent="like">
        <i class="icon s7-like"></i>
    </a>
</template>

<script>
    export default {
        props : {
            work : {
                required:  true
            },
            value : {
                type: Boolean,
                required:  true
            },
            likeClass: {
                type : String,
                required : false,
                default : 'btn-danger',
            }
        },
        methods: {
            like() {
                let endpoint = '/works/' + this.work + '/'
                endpoint += this.value ? 'dislike' : 'like'

                axios.get(endpoint)
                    .then(response =>{
                        this.$emit('input', !this.value)
                    })
                    .catch(error =>{
                        this.$notifier.error()
                    });
            }
        },
        computed : {
            elementClass : function(){
                return this.value === true ? this.likeClass : '';
            }
        }
    }
</script>

<style>
    a.liked i{
        color: red !important;

    }
</style>