<template>
    <basic-layout>
        <template slot="side">
            <span class="alternative-message text-right">
                Geen account? <router-link :to="{name: 'authentication.login'}">Inloggen</router-link>
            </span>
        </template>
        <template slot="title">
            Registratie
        </template>
        <template slot="content" v-if="registered === false">
            <div>
                <form class="sign-up-form" method="POST" action="">
                    <div v-if="errors !== ''" role="alert" class="alert alert-contrast alert-danger alert-dismissible">
                        <div class="message">
                            <strong>Er ging iets fout!</strong><br />
                            <span v-html="errors"></span>
                        </div>
                    </div>
                    <div class="form-group inline row">
                        <div class="col-sm-6">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-user"></i></span>
                                <input type="text" v-model="registration.firstname" placeholder="Voornaam" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-user"></i></span>
                                <input type="text" v-model="registration.lastname" placeholder="Achternaam"  class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group"><span class="input-group-addon"><i class="icon s7-mail"></i></span>
                            <input id="email" type="email" v-model="registration.email" placeholder="Email Adres"  class="form-control">
                        </div>
                    </div>
                    <div class="form-group inline row">
                        <div class="col-sm-6">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-lock"></i></span>
                                <input type="password" placeholder="Wachtwoord" v-model="registration.password" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-lock"></i></span>
                                <input type="password" placeholder="Opnieuw" v-model="registration.password_confirmation"  class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group"><span class="input-group-addon"><i class="icon s7-phone"></i></span>
                            <input type="text" v-model="registration.phone" placeholder="Telefoonnummer"  class="form-control">
                        </div>
                    </div>
                    <div class="form-group inline row">
                        <div class="col-sm-8">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-way"></i></span>
                                <input type="text" v-model="registration.street" placeholder="Straat + Nr." class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="input-group"><span class="input-group-addon"></span>
                                <input type="text" v-model="registration.zipcode" placeholder="Postcode" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group"><span class="input-group-addon"><i class="icon s7-home"></i></span>
                            <input type="text" v-model="registration.city" placeholder="Stad" class="form-control">
                        </div>
                    </div>
                    <div class="form-group login-submit">
                        <input @click.prevent="formSubmit" type="submit" class="btn btn-lg btn-primary btn-block" value="Registreren" :disabled="disableButton" />
                    </div>
                </form>
            </div>
        </template>
        <template slot="content" v-else>
            <div role="alert" class="alert alert-contrast alert-success alert-dismissible">
                <div class="message">
                    <strong>Geregistreerd!</strong><br />
                    <span>Welkom bij het lezerscollectief. Je ontvangt spoedig een email met daarin een link naar het kennismakingsformulier. Je zal gebruik kunnen maken van Mijn Lezerscollectief wanneer je dit formulier invult en je account wordt geactiveerd. </span>
                </div>
            </div>
        </template>
    </basic-layout>
</template>

<script>
    import BasicLayoutComponent from '../layouts/BasicLayoutComponent.vue'

    export default {
        components : {
            'basic-layout': BasicLayoutComponent
        },
        data() {
            return {
                registration: {
                    firstname : '',
                    lastname: '',
                    email: '',
                    password : '',
                    password_confirmation: '',
                    phone: '',
                    street: '',
                    zipcode: '',
                    city: '',
                },
                errors : '',
                registered : false,
                disableButton : false,
            }
        },
        methods : {
            formSubmit : function(){
                this.disableButton = true
                axios.post('/authentication/register', this.registration).then(response => {
                    this.disableButton = false
                    this.registered = true
                }).catch(error => {
                    this.disableButton = false

                    if(error.response.status === 422){
                        this.errors = 'Enkele velden zijn fout:<br />'

                        Object.entries(error.response.data.errors)
                            .forEach(([key, value]) => {
                                this.errors += '- <b>' + key + ':</b> ' + value.join() + '<br />'
                            })
                    }else{
                        this.errors = 'Gelieve opnieuw te proberen!'
                    }
                })
            }
        }
    }
</script>

<style>

</style>