<template>
    <router-link :to="{name : route}" class="dropdown-item"> <span :class="iconClass"> </span>{{ name }}</router-link>
</template>

<script>
    export default {
        props : {
            name : {
                type: String,
                required : true,
            },
            icon : {
                type: String,
                required : true,
            },
            route : {
                type: String,
                required : true,
            }
        },
        computed : {
            iconClass : function(){
                return 'icon s7-' + this.icon
            }
        }
    }
</script>

<style>

</style>