Ò<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-heading-divider panel-heading-full-width">
            <span class="title">Download Kalender</span></div>
        <div class="panel-body">
            <p>Kopieer of open onderstaande link voor het gebruik van de kalender met leesdagen in een extern programma. Je kan deze link ook doorgeven aan participanten van de leesgroep.</p>
            <br />
            <p><strong><a style="word-wrap: break-word;" :href="link.replace('https://', 'webcal://')">{{ link }}</a></strong></p>
        </div>
    </div>
</template>

<script>
    export default {
        computed : {
            link : function(){
                return this.$parent.group.calendar_url
            },
        }
    }
</script>

<style>

</style>