<script>
    import { Doughnut } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        props : {
            data : {
                required : true,
            }
        },
        watch: {
            data: function (newData, oldData) {
                let labels = ['5-14 Jaar', '15-24 Jaar', '25-34 Jaar', '35-44 Jaar', '45-54 Jaar', '55-64 Jaar', '65-74 Jaar', '75-84 Jaar', '85+ Jaar', 'Niet geweten'];

                let datasets = [
                    {
                        data : [
                            newData['5-14'],
                            newData['15-24'],
                            newData['25-34'],
                            newData['35-44'],
                            newData['45-54'],
                            newData['55-64'],
                            newData['65-74'],
                            newData['75-84'],
                            newData['85+'],
                            newData['Niet geweten'],
                        ],
                        backgroundColor : ['rgb(114,102,186)', 'rgb(35,183,229)', 'rgb(39,194,76)', 'rgb(250,215,51)', 'rgb(240,80,80)', 'rgb(91,81,148)', 'rgb(28,146,183)', 'rgb(31,155,60)', 'rgb(200,172,40)', 'rgb(192,64,64)'],
                    }
                ]

                this.renderChart(
                    {
                        labels: labels,
                        datasets: datasets
                    },
                    {
                        legend: {
                            position : 'right'
                        },
                    }
                )
            }
        },

    }
</script>