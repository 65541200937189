<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="panel panel-default panel-table">
                <div class="filters">
                    <div class="filters-title">
                        Periodes
                    </div>
                    <div class="filters-content d-flex flex-row justify-content-between d-flex align-items-center">
                        <div class="p-2">

                        </div>
                        <div class="p-2">
                            <router-link class="btn btn-rounded btn-space btn-success" :to="{ name: 'periods.create', params: { groupId: groupId }}">Toevoegen</router-link>
                        </div>
                    </div>
                </div>
                <hr style="margin-top: 3px;margin-bottom: 3px;" />
                <div class="panel-body">
                    <tabler :api-url="apiUrl" url="/users" :fields="fields" :history="false">
                        <template slot="actions" slot-scope="props">
                            <div class="table-actions">
                                <a class="icon" @click.prevent="switchPeriod(props.rowData.id)"><i class="s7-look"></i></a>
                                <router-link class="icon" :to="{ name: 'periods.edit', params: { groupId : groupId,  periodId: props.rowData.id}}"><i class="s7-pen"></i></router-link>
                                <router-link class="icon" :to="{ name: 'periods.delete', params: { groupId : groupId,  periodId: props.rowData.id}}"><i class="s7-trash"></i></router-link>
                            </div>
                        </template>
                    </tabler>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                fields: [
                    {
                        name : 'start',
                        title : 'Begin',
                        sortField: 'start',
                        callback: 'formatDate|D MMMM YYYY'
                    },
                    {
                        name : 'end',
                        title : 'Einde',
                        sortField: 'end',
                        callback: 'formatDate|D MMMM YYYY'
                    },
                    {
                        name: '__slot:actions',
                        title: '',
                        dataClass: 'actions float-right'
                    }
                ],
            }
        },
        props : {
            groupId : {
                required: true,
            }
        },
        computed : {
            apiUrl :  function(){
                return '/groups/' + this.groupId + '/periods';
            }
        },
        methods : {
            switchPeriod : function(id){
                return this.$parent.switchPeriod(id)
            }
        }
    }
</script>

<style>

</style>