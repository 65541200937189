<script>
    import {Line} from 'vue-chartjs'

    export default {
        extends: Line,
        props : {
          data : {
              required : true,
              type : Array,
          }
        },
        watch: {
            data: function (newData, oldData) {
                let labels = newData.map(x => x.day);

                let datasets = [];

                datasets.push({
                    label: 'Taalvaardigheid',
                    data: newData.map(x => x.language_proficiency),
                    backgroundColor: 'rgb(250,215,51)',
                    borderColor : 'rgb(250,215,51)',
                    fill: false,
                })

                datasets.push({
                    label: 'Activiteiten',
                    data: newData.map(x => x.activities),
                    backgroundColor: 'rgb(35,183,229)',
                    borderColor : 'rgb(35,183,229)',
                    fill: false,
                })

                datasets.push({
                    label: 'Participatie',
                    data: newData.map(x => x.participation),
                    backgroundColor: 'rgb(250,34,0)',
                    borderColor : 'rgb(250,34,0)',
                    fill: false,
                })

                datasets.push({
                    label: 'Beleving samen lezen',
                    data: newData.map(x => x.reading_together),
                    backgroundColor: 'rgb(39,194,76)',
                    borderColor : 'rgb(39,194,76)',
                    fill: false,
                })

                datasets.push({
                    label: 'Beleving leesgroep',
                    data: newData.map(x => x.experience_reading_group),
                    backgroundColor: 'rgb(114,102,186)',
                    borderColor : 'rgb(114,102,186)',
                    fill: false,
                })

                this.renderChart(
                    {
                        labels: labels,
                        datasets: datasets
                    },
                    {
                        legend: {
                            position : 'top'
                        },
                        tooltips: {
                            mode: 'label'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    max: 5,
                                    min: 1,
                                    stepSize: 1,
                                    suggestedMin: 0,
                                    suggestedMax: 5
                                },
                            }],
                            xAxes : [{
                                gridLines : {
                                    display : false
                                }
                            }]
                        },
                    }
                )
            }
        },

    }
</script>