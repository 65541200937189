const PermissionContainer = {
    userContainer: {
        roles : []
    },

    set user(user){
        this.userContainer = user
    },

    get user(){
        return this.userContainer
    },

    get role(){
        return this.userContainer.roles[0]
    },

    get permissions(){
        return this.role.permissions
    },

    can(permissionName, ownerId){
        if(ownerId === this.user.id){
            return true
        }

        let permissionCheck = this.permissions.find(function(permission){
            return permission.name === permissionName
        })

        return !_.isEmpty(permissionCheck)
    }
}

export default PermissionContainer;