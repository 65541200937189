<template>
    <destroy-view type="Leesgroep" backRoute="groups.index" :apiUrl="apiUrl"></destroy-view>
</template>

<script>
    import DestroyComponent from '../layouts/DestoryComponent.vue'

    export default {
        components : {
            'destroy-view' : DestroyComponent
        },
        props : {
            groupId : {
                required : true
            }
        },
        computed : {
            apiUrl : function () {
                return '/groups/' + this.groupId
            }
        }
    }
</script>

<style>

</style>