<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <vue-editor v-model="value"
                    :id="name"
                    :placeholder="title"
                    :editorToolbar="customToolbar"
                    useCustomImageHandler
                    @imageAdded="handleImageAdded"
        ></vue-editor>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor'
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'
    import _ from 'lodash'

    export default {
        mixins : [BasicFieldMixin],
        components: {
            VueEditor
        },
        props: {
            toolbar : {
                type : Array,
                required: false,
            }
        },
        data(){
          return {
              customToolbar:
                  [[{
                      header: [1, 2, 3, 4, 5, 6, !1]
                  }], ["bold", "italic", "underline"], [{
                      color: []
                  }], [{
                      align: "justify"
                  }, {
                      align: ""
                  }, {
                      align: "center"
                  }, {
                      align: "right"
                  }], [{
                      list: "ordered"
                  }, {
                      list: "bullet"
                  }], ["blockquote", "link", "image"]],
          }
        },
        methods: {
            handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
                let data = new FormData();
                data.append('file', file)

                axios.post('/upload/editor', data).then(response => {
                    let url = '/storage/uploads/editor/' + response.data.file
                    Editor.insertEmbed(cursorLocation, 'image', url);
                    resetUploader();
                })
                    .catch(error => {
                        if('errors' in error.response.data && 'file' in error.response.data.errors){
                            this.$notifier.error('Er ging iets mis bij het uploaden van het bestand!', error.response.data.errors.file.join(','))
                        }else{
                            this.$notifier.error('Er ging iets mis!', 'We konden het bestand niet uploaden!')
                        }
                    });
            }
        },
        created(){
            this.customToolbar = _.isEmpty(this.toolbar) ? this.customToolbar : this.toolbar
        }
    }
</script>

<style>
    .quillWrapper .ql-toolbar, .quillWrapper .ql-container{
        background-color : #FFFFFF;
        border :  3px solid rgb(235, 235, 235);
    }
</style>
