<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-heading-divider panel-heading-full-width"><span class="title">Periode {{ config.submitText }}</span></div>
        <div class="panel-body">
            <former :config="config" @form-success="formSuccess">
                <div class="row">
                    <div class="col-md-12">
                        <set-show title="Periode" description="Hoelang loopt deze periode?" name="periodType">
                            <set-item title="Deze periode heeft een einddatum" name="temporary">
                                <div class="row">
                                    <div class="col-md-6">
                                        <field-date title="Startdatum" name="start"></field-date>
                                    </div>
                                    <div class="col-md-6">
                                        <field-date title="Einddatum" name="end"></field-date>
                                    </div>
                                </div>
                            </set-item>
                            <set-item title="Deze periode heeft nog geen einddatum" name="infinity">
                                <div class="row">
                                    <div class="col-md-6">
                                        <field-date title="Startdatum" name="start"></field-date>
                                    </div>
                                </div>
                            </set-item>
                        </set-show>
                    </div>
                </div>
            </former>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {},
            }
        },
        created() {
            if(this.$route.name === 'periods.create'){
                this.config = {
                    submitText: 'Toevoegen',
                    messageSuccessTitle: 'Periode Toegevoegd!',
                    storeUrl: '/groups/' + this.groupId + '/periods',
                    update: false
                }
            }else if(this.$route.name === 'periods.edit'){
                this.config = {
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Periode Gewijzigd!',
                    dataUrl: '/groups/' + this.groupId + '/periods/' + this.periodId,
                    updateUrl: '/groups/' + this.groupId + '/periods/' + this.periodId,
                    update: true
                }
            }else{
                this.$notifier.error('Ongekende route!')
            }
        },
        methods : {
            transformData : function(data){
                if(data.end === 0 || data.end === null){
                    data.end = null
                    data.periodType = 'infinity'
                    data.start *= 1000
                }else{
                    data.periodType = 'temporary'
                    data.end *= 1000
                    data.start *= 1000
                }

                return data
            },
            formSuccess : function(response){
                return this.$router.push({name: 'periods', params: { groupId : this.groupId}});
            }
        },
        props : {
            periodId : {
                required : false,
            },
            groupId : {
                required : false,
            },
        }
    }
</script>

<style>

</style>