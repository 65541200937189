
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Vue
import Vue from 'vue';

// Lodash
window._ = require('lodash');

// Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = '/api/';

// Vue Router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Vue events
import VueEvents from 'vue-events';
Vue.use(VueEvents);

// Notifier
import Notifier from './notifier/Plugin';
Vue.use(Notifier);

import Permissioner from './permissioner/Plugin';
Vue.use(Permissioner);

// Routes
import routes from './pages/routes';

// Cookies
import Cookies from 'js-cookie';

// Moment
import moment from 'moment';


// Former
Vue.component('former', require('./former/components/FormerComponent.vue').default);
Vue.component('send', require('./former/components/SendComponent.vue').default);

// Former Fields
Vue.component('field-hidden', require('./former/components/fields/HiddenFieldComponent.vue').default);
Vue.component('field-string', require('./former/components/fields/StringFieldComponent.vue').default);
Vue.component('field-password', require('./former/components/fields/PasswordFieldComponent.vue').default);
Vue.component('field-number', require('./former/components/fields/NumberFieldComponent.vue').default);
Vue.component('field-date', require('./former/components/fields/DateFieldComponent.vue').default);
Vue.component('field-datetime', require('./former/components/fields/DateTimeFieldComponent.vue').default);
Vue.component('field-time', require('./former/components/fields/TimeFieldComponent.vue').default);
Vue.component('field-email', require('./former/components/fields/EmailFieldComponent.vue').default);
Vue.component('field-icon', require('./former/components/fields/IconFieldComponent.vue').default);
Vue.component('field-text', require('./former/components/fields/TextFieldComponent.vue').default);
Vue.component('field-single-upload', require('./former/components/fields/SingleUploadFieldComponent.vue').default);
Vue.component('field-select', require('./former/components/fields/SelectFieldComponent.vue').default);
Vue.component('field-select2', require('./former/components/fields/SelectExtendedFieldComponent.vue').default);
Vue.component('field-radio', require('./former/components/fields/RadioFieldComponent.vue').default);
Vue.component('field-checkbox', require('./former/components/fields/CheckboxFieldComponent.vue').default);
Vue.component('field-switch', require('./former/components/fields/SwitchFieldComponent.vue').default);
Vue.component('field-wysiwyg', require('./former/components/fields/WysiwygFieldComponent.vue').default);


// Former Sets
Vue.component('set-item', require('./former/components/sets/SetItemComponent.vue').default);
Vue.component('set-show', require('./former/components/sets/ShowSetComponent.vue').default);
Vue.component('set-tab', require('./former/components/sets/TabSetComponent.vue').default);
Vue.component('tab-set-item', require('./former/components/sets/TabSetItemComponent.vue').default);
Vue.component('set-multi', require('./former/components/sets/MultiSetComponent.vue').default);

// Tabler
Vue.component('tabler', require('./tabler/components/TablerComponent.vue').default);
Vue.component('search-filter', require('./tabler/components/SearchFilterComponent.vue').default);
Vue.component('boolean-filter', require('./tabler/components/BooleanFilterComponent.vue').default);
Vue.component('select-filter', require('./tabler/components/SelectFilterComponent.vue').default);
Vue.component('multi-select-filter', require('./tabler/components/MultiSelectFilterComponent.vue').default);

// Pages
import LoginComponent from "./pages/authentication/LoginComponent";

Vue.component('login-page', LoginComponent);
Vue.component('register-page', require('./pages/authentication/RegisterComponent.vue').default);

const router = new VueRouter({
    routes // short for `routes: routes`
});

var vm = new Vue({
    router,
    el: "#app",
    data : {
        accessToken : '',
        expiring : '',
    },
    methods : {
        // Set an access token which will be used when making XHR requests
        // Also set an cookie
        setAccessToken : function(token, expiring){
            this.accessToken = token;
            this.expiring = moment().add(expiring, 'seconds').subtract(60, 'seconds').unix();

            Cookies.set('access_token', this.accessToken, { expires: 1 });
            Cookies.set('expiring', this.expiring, { expires: 1 });

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            this.startRefreshTimer(this.expiring);
        },

        logout : function(){
            this.accessToken = '';
            this.expiring = '';

            Cookies.set('access_token', '');
            Cookies.set('expiring', '');
        },

        authenticated : function(){
            return this.accessToken !== '';
        },

        // Start an timer which refreshes the token, takes the expires at time to determine when to refresh the token
        startRefreshTimer : function(expires_at){
            let sheduleAfter = (expires_at - moment().unix())*1000;

            // Less than ten seconds, then refresh now
            if(sheduleAfter <= 1000){
                sheduleAfter = 10;
            }

            console.log('Shedule refresh in' + (sheduleAfter/1000) + 'seconds');
            setTimeout(this.refreshToken, sheduleAfter);
        },

        refreshToken(){
            console.log('Token refresh');

            axios.get('/authentication/refresh').then(response => {
                this.setAccessToken(response.data.access_token, response.data.expires_in);
            }).catch(error => {
                this.$router.push({name : 'authentication.login'});
            });
        },
    },
    created(){
        window.axios.interceptors.response.use(function (response) {
            return response;
        }, error => {
            // When an not authenticated response comes in, redirect to login
            if(error.response.status === 401){
                this.$router.push({name : 'authentication.login'});
            }


            return Promise.reject(error);
        });
    },
});


global.vm = vm;