import Field from "./Field";

class FieldStore{
    constructor(){
        this.fields = []
    }

    value(name, identifier = null){
        let field = this.getField(name, identifier)

        return field !== undefined ? field.value : ''
    }

    error(name, identifier = null){
        let field = this.getField(name, identifier)

        return field !== undefined ? field.error : ''
    }

    register(name, identifier = null){
        if(this.getField(name, identifier) !== undefined){
            return;
        }else{
            let field = new Field(name, identifier)

            this.fields.push(field);
        }
    }

    hasError(name, identifier = null){
        let field = this.getField(name, identifier)

        return field !== undefined ? field.hasError() : false
    }

    setValue(value, name, identifier = null){
        let field = this.getField(name, identifier)

        return field !== undefined ? field.value = value : null
    }

    setError(error, name, identifier = null){
        let field = this.getField(name, identifier)

        return field !== undefined ? field.error = error : null
    }

    getField(name, identifier = null){
        for(let field of this.fields){
            if(field.isField(name, identifier)){
                return field
            }
        }

        return undefined
    }

    deserializeData(response, scope = ''){
        for(const [key, value] of Object.entries(response)) {
            if(_.isObject(value) === true && _.isArray(value) === false){
                this.deserializeData(response[key],  scope + key + '.')
                continue;
            }

            this.setValue(value, scope + key, null)
        }
    }

    deserializeErrors(errors, scope = ''){
        for(const [key, error] of Object.entries(errors)) {
            if(_.isObject(error) === true && _.isArray(error) === false){
                this.deserializeErrors(errors[key],  scope + key + '.')
                continue;
            }

            if(_.isArray(error)){
                this.setError(error.join(' en '), scope + key, null)
            }else{
                this.setError(error, scope + key, null)
            }
        }
    }

    serializeData(scope = ''){
        var output = {}

        for(let field of this.fields){
            let segments = field.name.split('.')
            let scope = output

            for(var i = 0;i < segments.length;i++){
                if(i === segments.length - 1){
                    scope[segments[i]] = field.value
                }else{
                    if(segments[i] in scope){
                        scope = scope[segments[i]]
                    }else{
                        scope[segments[i]] = {}
                        scope = scope[segments[i]]
                    }
                }
            }
        }

        return output
    }

    reset(){
        for(let field of this.fields){
            field.error = ''
            field.data = ''
        }
    }
}

export default FieldStore