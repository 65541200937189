<template>
    <div class="mai-splash-screen">
        <div class="mai-wrapper mai-login">
            <div class="main-content container">
                <div class="splash-container row">
                    <div class="col-md-6 user-message">
                        <span class="splash-message text-right">
                            Welkom!<br>
                            Het Lezerscollectief
                        </span>
                        <slot name="side"></slot>
                    </div>
                    <div class="col-md-6 form-message" id="app">
                        <img src="/images/logo.png" alt="logo" width="169" height="28" class="logo-img mb-4">
                        <span class="splash-description text-center mt-5 mb-5"><slot name="title"></slot></span>
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                msg: 'Hello world!'
            }
        }
    }
</script>

<style>

</style>