<template>
    <div>
        <span class="table-filter-title">{{ title }}</span>
        <div class="filter-container">
            <a class="" :class="{'btn btn-space btn-danger btn-lg active' : flipped, 'btn btn-space btn-lg btn-dark' : !flipped}" style="width: 100%;text-align: left; color:white;" @click.prevent="flip">
                <slot v-if="!flipped" name="on"></slot>
                <slot v-else name="off"></slot>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            title: {
                type : String,
                required : false
            },
            filter: {
                type : String,
                required : true
            },
        },
        data () {
            return {
                flipped: false,
            }
        },
        methods: {
            flip () {
                this.flipped = !this.flipped

                this.$events.fire('filter', {
                    filter : this.filter,
                    selected : [this.flipped]
                });
            },
        },
        mounted (){
            this.$events.$on('set-filter' , eventData => {
                if(this.filter in eventData){
                    this.flipped = eventData[this.filter][0][0]
                }
            })
        },
    }
</script>