<template>
    <div class="panel" v-if="shouldHide === false">
        <div class="panel-heading panel-heading-color panel-heading-color-dark">
            <span class="title">Actie Vereist</span>
            <span class="panel-subtitle">De gebruiker moet geactiveerd worden. Wat wilt u doen?</span>
        </div>
        <div class="panel-body">
            <button class="btn btn-space btn-success btn-xs" @click.prevent="approve"> <i class="icon icon-left s7-check"></i> Activeren</button>
            <button class="btn btn-space btn-danger btn-xs" @click.prevent="refuse"> <i class="icon icon-left s7-trash"></i> Verwijderen</button>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            user : {
                type: Number,
                required: true
            }
        },
        methods : {
            approve : function(){
                axios.get('/users/' + this.user + '/approve')
                    .then(response => {
                        this.$notifier.success('Gebruiker geactiveerd!');
                        this.shouldHide = true;
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            },
            refuse : function () {
                axios.get('/users/' + this.user + '/refuse')
                    .then(response => {
                        this.$notifier.success('Gebruiker verwijderd!');
                        window.location = '/users'
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            }
        },
        data() {
            return {
                shouldHide : false,
            }
        }
    }
</script>

<style>

</style>