<!--
<template>
    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
        <div class="table-responsive noSwipe">
            <vuetable class="table table-striped table-hover dataTable no-footer"
                      ref="vuetable"
                      :api-url="apiUrl"
                      :fields="fields"
                      :css="css"
                      pagination-path="pagination"
                      :append-params="moreParams"
                      @vuetable:pagination-data="onPaginationData"
            ></vuetable>
        </div>
        <div class="row mai-datatable-footer">
            <div class="col-sm-5">
                <div class="dataTables_info">
                    <vuetable-pagination-info ref="paginationInfo"
                                              info-template="{from} - {to} van {total} items"
                                              no-data-template="Geen relevante data"
                    ></vuetable-pagination-info>
                </div>
            </div>
            <div class="col-sm-7">
                <vuetable-pagination-bootstrap ref="pagination"
                                               @vuetable-pagination:change-page="onPageChange"
                ></vuetable-pagination-bootstrap>
            </div>
        </div>
    </div>
</template>
-->

<script>
    import Vue from 'vue'
    import {Vuetable, VuetablePaginationInfo} from 'vuetable-2'
    import VuetablePaginationBootstrap from './VuetablePaginationBootstrap.vue'
    import ActionsComponent from './ActionsComponent.vue'
    import QueryString from 'query-string'
    import moment from 'moment'
    import 'moment/locale/nl-be';

    Vue.component('actions', ActionsComponent);

    export default {
        components: {
            Vuetable,
            VuetablePaginationBootstrap,
            VuetablePaginationInfo,
        },
        props: {
            apiUrl: {
                type : String,
                required : true
            },
            fields : {
                type: Array,
                required : true,
            },
            history: {
                type: Boolean,
                default: true,
            },
            includes : {
                type: Array,
                required: false,
            },
            filters : {
                type : Array,
                required : false,
            },
            initialSort : {
                type: Array,
                required: false,
            }
        },
        data : function() {
            return {
                css: {
                    tableClass : 'table table-striped table-borderless',
                    ascendingIcon: 's7-angle-up-circle',
                    descendingIcon: 's7-angle-down-circle',
                    sortableIcon :  's7-filter'
                },
                parameters : {
                    search : null,
                    page : 1,
                    sort : null,
                    filters : {}
                },
            }
        },
        methods: {
            getVueTable(){
                return this.$children[0]
            },
            onPaginationData (paginationData) {
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
            },
            transform: function(data) {
                var transformed = {}

                transformed.pagination = {
                    total: data.meta.total,
                    per_page: data.meta.per_page,
                    current_page: data.meta.current_page,
                    last_page: data.meta.last_page,
                    next_page_url: data.links.next,
                    prev_page_url: data.links.prev,
                    from: data.meta.from,
                    to: data.meta.to
                }

                transformed.data = data.data

                return transformed
            },
            getSortParam: function(sortOrder) {
                //console.log(sortOrder)


                return sortOrder.map(function(sort) {
                    return (sort.direction === 'desc' ? '-' : '') + sort.sortField
                }).join(',')
            },
            onSearch (searchString) {
                this.parameters.search = searchString

                Vue.nextTick( () => this.getVueTable().refresh())
            },
            onFilter (filterData) {
                this.$set(this.parameters.filters, filterData.filter, filterData.selected)

                Vue.nextTick(() => this.getVueTable().refresh())
            },
            onPageChange(page){
                if(page === 'next'){
                    this.$set(this.parameters, 'page', this.parameters.page + 1)
                }else if(page === 'prev'){
                    this.$set(this.parameters, 'page', this.parameters.page - 1)
                }else{
                    this.$set(this.parameters, 'page', page)
                }

                this.getVueTable().changePage(page)
            },
            formatDate (value, fmt = 'D MMMM YYYY') {
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            },
            fetch(apiUrl, httpOptions) {
                return axios.get(apiUrl, httpOptions)
            },

            // ----------------------
            // Vue Table Methods Proxy
            // ----------------------
            showField(index){
                this.$refs.vuetable.showField(index)
            },
            hideField(index){
                this.$refs.vuetable.hideField(index)
            },

            // ----------------
            // Render functions
            // ----------------

            renderVuetable(h) {
                return h(
                    'vuetable',
                    {
                        ref: 'vuetable',
                        class:{'table': true, 'table-striped': true, 'table-hover': true, 'dataTable': true, 'no-footer': true},
                        props: {
                            apiUrl: this.apiUrl,
                            fields: this.fields,
                            httpFetch : this.fetch,
                            paginationPath: "pagination",
                            css: this.css,
                            appendParams: this.moreParams,
                            sortOrder : this.initialSort,
                            'no-data-template' : 'Geen gegevens beschikbaar'
                        },
                        on: {
                            'vuetable:pagination-data': this.onPaginationData,
                        },
                        scopedSlots: this.$vnode.data.scopedSlots
                    }
                )
            },
            renderPaginationInfo(h) {
                return h('div', {
                        class: {'col-sm-5': true}
                        }, [
                            h('div', {
                                class: {'dataTables_info': 'true'}
                            }, [
                                h('vuetable-pagination-info', {
                                    ref: 'paginationInfo' ,
                                    props: {
                                        'info-template': '{from} - {to} van {total} items',
                                        'no-data-template': 'Geen relevante data'
                                    }
                                }),
                        ]),
                    ]);
            },
            renderPagination(h) {
                return h('div', {
                            class: {'col-sm-7': true}
                        }, [
                            h('vuetable-pagination-bootstrap', {
                                ref: 'pagination',
                                on: {
                                    'vuetable-pagination:change-page': this.onPageChange
                                }
                            })
                        ]);
            }

        },
        computed : {
            moreParams : function(){
                let params = {}

                // Filters
                for(const filter in this.parameters.filters){
                    let filterName = 'filter[' + filter + ']';
                    let filterData = this.parameters.filters[filter]

                    if(filterData.length === 0){
                        delete params[filterName];
                    }else{
                        params[filterName] = filterData.join()
                    }
                }

                // Search
                if(_.isEmpty(this.parameters.search) === false){
                    params['search'] = this.parameters.search
                }

                if(this.history === true){
                    //location.search = QueryString.stringify({parameters: JSON.stringify(this.parameters)})
                }

                // Base Includes
                if(_.isEmpty(this.includes) === false){
                    params['include'] = this.includes.join();
                }

                // Base Filters
                if(_.isEmpty(this.filters) === false){
                    for(const filter of this.filters){
                        let filterName = 'filter[' + filter.name + ']';
                        params[filterName] = filter.parameters.join()
                    }
                }

                return params;
            }
        },
        mounted (){
            this.$events.$on('search' , eventData => this.onSearch(eventData))
            this.$events.$on('filter' , eventData => this.onFilter(eventData))

            // Set values of search and filters if read from url
            this.$events.fire('set-search', this.parameters.search)
            this.$events.fire('set-filter', this.parameters.filters)
            setTimeout(() => {
                this.getVueTable().changePage(this.parameters.page)
            }, 1000);

        },
        beforeMount (){
            if(window.location.search && this.history === true){
                //let params = JSON.parse(QueryString.parse(location.search).parameters);
                //Vue.set(this, 'parameters', params)

                //Vue.nextTick(() => this.getVueTable().refresh())
            }
        },
        render(h) {
            return h(
                'div',
                {
                    class: { 'dataTables_wrapper': true, 'container-fluid': true, 'dt-bootstrap4': true, 'no-footer':true }
                },
                [
                    h('div', {
                        class: {'table-responsive': true, 'noSwipe': true}
                    }, [this.renderVuetable(h)]),
                    h('div', {
                        class: {'row mai-datatable-footer': true}
                    }, [
                        this.renderPaginationInfo(h),
                        this.renderPagination(h)
                    ])
                ]
            )
        },
        watch: {
            // call again the method if the route changes
            '$route': function(newData, oldData){
                Vue.nextTick(() => this.getVueTable().refresh())
            }
        },

    }
</script>

<style>
    .table-actions a{
        float: right;
        margin-left: 3px;
    }

    .table-actions{
        min-width: 86px;
    }
</style>
