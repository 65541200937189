<template>
  <div class="row" id="works" xmlns="">
    <div class="col-lg-3">
      <div class="panel">
        <div class="panel-heading panel-heading-color panel-heading-color-success">
          <div class="tools">
            <router-link :to="{name : 'users.create'}"><span class="icon s7-plus"></span></router-link>
          </div>
          <span class="title">Gebruikers</span>
        </div>
        <div class="panel-body">
          <search-filter></search-filter>
          <select-filter
              title="Rol"
              filter="role"
              :options="roleOptions"
              v-if="$route.name !== 'users.unapproved'"
          ></select-filter>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="panel panel-default panel-table panel-border-color-success">
        <div class="panel-body">
          <tabler api-url="/users" url="/users" :fields="fields" :filters="filters">
            <template slot="actions" slot-scope="props">
              <div class="table-actions">
                <router-link class="icon" :to="{ name: 'users.show', params: { userId: props.rowData.id }}">
                  <i class="s7-look"></i></router-link>
                <router-link class="icon" :to="{ name: 'users.edit', params: { userId: props.rowData.id  }}">
                  <i class="s7-pen"></i></router-link>
                <router-link class="icon" :to="{ name: 'users.delete', params: { userId: props.rowData.id }}">
                  <i class="s7-trash"></i></router-link>
              </div>
            </template>
          </tabler>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      fields: [
        {
          name: 'firstname',
          title: 'Voornaam',
          sortField: 'firstname'
        },
        {
          name: 'lastname',
          title: 'Achternaam',
          sortField: 'lastname'
        },
        {
          name: 'email',
          title: 'Email Adres',
          sortField: 'email'
        },
        {
          name: 'role',
          title: 'Rol',
          callback: function (value, fmt) {
            return value.name;
          }
        },
        {
          name: 'hasForm',
          title: 'Formulier',
          sortField: 'form_id',
          callback: function (value, fmt) {
            if (value === false) {
              return '<span class="badge badge-danger">Niet Aanwezig</span>';
            } else {
              return '<span class="badge badge-success">Ingevuld</span>';
            }
          }
        },
        {
          name: '__slot:actions',
          title: '',
          dataClass: 'actions right-aligned'
        },
      ],
      roleOptions: {}
    }
  },
  created() {
    axios.get('/users/roles')
        .then(response => {
          response.data.data.filter((value, key) => {
            Vue.set(this.roleOptions, value.id, value.name);
          });
        })
        .catch(error => {
          this.$notifier.error()
        })
  },
  computed: {
    filters: function () {
      if (this.$route.name === 'users.unapproved') {
        return [{
          name: 'role',
          parameters: [4]
        }];
      } else {
        return []
      }
    }
  }
}
</script>

<style>

</style>