<template>
    <div class="form-group">
        <input type="hidden"
               class="form-control"
               v-bind:class="{'is-invalid': hasError}"
               v-bind:name="name"
               v-model="value"
        />
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin]

    }
</script>
