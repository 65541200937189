<template>
    <div class="panel" v-if="shouldHide === false">
        <div class="panel-heading panel-heading-color panel-heading-color-dark">
            <span class="title">Goedkeuring Vereist</span>
            <span class="panel-subtitle">Wat wilt u doen?</span>
        </div>
        <div class="panel-body">
            <button class="btn btn-space btn-success btn-xs" @click.prevent="approve"> <i class="icon icon-left s7-check"></i> Goedkeuren</button>
            <button class="btn btn-space btn-danger btn-xs" @click.prevent="refuse"> <i class="icon icon-left s7-close"></i> Afkeuren</button>
            <form v-if="isRefused">
                <hr />
                <h5>Waarom is het werk afgekeurd?</h5>
                <div class="form-group">
                    <textarea v-model="message" rows="10" class="form-control"></textarea>
                </div>
                <button @click.prevent="refuse" class="btn btn-danger"><i class="icon icon-left s7-close"></i> Afkeuren</button>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            work : {
                required: true
            }
        },
        methods : {
            approve : function(){
                axios.get('/works/' + this.work + '/approve')
                    .then(response => {
                        this.$notifier.success('Werk goedgekeurd!');
                        this.shouldHide = true;
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            },
            refuse : function () {
                if(this.isRefused === false){
                    // First refuse click
                    this.isRefused = true
                }else{
                    // Second refuse click
                    axios.post('/works/' + this.work + '/refuse', {
                        'message' : this.message
                    })
                        .then(response => {
                            this.$notifier.success('Werk Afgekeurd!');
                            this.shouldHide = true;
                        })
                        .catch(error => {
                            this.$notifier.error()
                        })

                }
            }
        },
        data() {
            return {
                message : null,
                isRefused : false,
                shouldHide : false,
            }
        }
    }
</script>

<style>

</style>