<script>
    import {Bar} from 'vue-chartjs'

    export default {
        extends: Bar,
        props : {
            globalMeans : {
                required : true,
                type: Object
            },
            experiences : {
                required : true,
                type: Array
            }
        },
        computed : {
            localMeans : function(){
                let data = {
                    language_proficiency : 0,
                    activities : 0,
                    participation : 0,
                    reading_together : 0,
                    experience_reading_group : 0,
                }

                let count = 0;

                for(let experience of this.experiences){
                    if(experience.is_present === true){
                        data.language_proficiency += experience.language_proficiency
                        data.activities += experience.activities
                        data.participation += experience.participation
                        data.reading_together += experience.reading_together
                        data.experience_reading_group += experience.experience_reading_group

                        count++;
                    }
                }


                if(count > 0){
                    data.language_proficiency /= count
                    data.activities /= count
                    data.participation /= count
                    data.reading_together /= count
                    data.experience_reading_group /= count
                }

                return data
            },
        },
        watch: {
            globalMeans: function (newData, oldData) {
                this.rerender(this.localMeans, newData)
            },
            localMeans: function (newData, oldData) {
                this.rerender(this.localMeans, this.globalMeans)
            }
        },
        methods : {
            rerender : function(localMeans, globalMeans){
                let labels = ['Taalvaardigheid', 'Activiteiten', 'Participatie', 'Beleving Samen Lezen', 'Beleving Leesgroep'];

                let datasets = [];

                datasets.push({
                    label: 'Gemiddelde Leesdag',
                    data: [
                        localMeans.language_proficiency,
                        localMeans.activities,
                        localMeans.participation,
                        localMeans.reading_together,
                        localMeans.experience_reading_group,
                    ],
                    backgroundColor: 'rgb(250,215,51)',
                    borderColor : 'rgb(250,215,51)',
                    fill: false,
                })


                datasets.push({
                    label: 'Gemiddelde Leesgroep',
                    data: [
                        globalMeans.language_proficiency,
                        globalMeans.activities,
                        globalMeans.participation,
                        globalMeans.reading_together,
                        globalMeans.experience_reading_group,
                    ],
                    backgroundColor: 'rgb(35,183,229)',
                    borderColor : 'rgb(35,183,229)',
                    fill: false,
                })


                this.renderChart(
                    {
                        labels: labels,
                        datasets: datasets
                    },
                    {
                        legend: {
                            position : 'top'
                        },
                        tooltips: {
                            mode: 'label'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    max: 5,
                                    min: 1,
                                    stepSize: 1,
                                    suggestedMin: 0,
                                    suggestedMax: 5
                                },
                            }],
                            xAxes : [{
                                gridLines : {
                                    display : false
                                }
                            }]
                        },
                    }
                )
            }
        }

    }
</script>