<template>
    <former :config="config" @form-success="formSuccess">
        <field-select2 title="Ontvangers" name="recipients" :max="maxRecipients" :multiple="true" :searchable="true" :options="recipientOptions"></field-select2>
        <field-wysiwyg title="Bericht" name="message"></field-wysiwyg>
    </former>
</template>

<script>
    export default {
        data(){
            return {
                recipientOptions : [],
                config: {
                    submitText: 'Stuur',
                    messageSuccessTitle: 'Bericht Verstuurd',
                    storeUrl: '/conversations',
                    update: false
                },
                maxRecipients : 10
            }
        },

        created(){
            axios.get('/users/list')
                .then(response => {
                    if(this.$permissioner.role !== undefined && this.$permissioner.role.name === 'Administrator'){
                        this.maxRecipients = undefined
                        this.recipientOptions.push({
                            id : 0,
                            name : 'Alle Leesbegeleiders'
                        })
                    }

                    response.data.data.forEach((user)  => {
                        this.recipientOptions.push({
                            id : user.id,
                            name : user.firstname + " " + user.lastname
                        })
                    });
                }).catch(error => {
                    this.$notifier.error()
                })
        },
        methods : {
            formSuccess : function(){
                this.$router.push({name : 'conversations.index'})
            }
        }
    }
</script>

