<template>
    <div class="table-actions">
        <a :href="showRoute" class="icon"><i class="s7-look"></i></a>
        <a :href="editRoute" class="icon"><i class="s7-pen"></i></a>
        <a href="#" class="icon" @click.prevent="itemAction('delete-item', rowData, rowIndex)"><i class="s7-trash"></i></a>
    </div>
</template>

<script>
    export default {
        name : 'actions',
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        computed: {
            showRoute(){
                return this.route() + this.rowData.id;
            },

            editRoute(){
                return this.route() + this.rowData.id +  '/edit';
            },

            destroyRoute(){
                return this.route() + this.rowData.id +  '/destroy';
            }
        },
        methods: {
            itemAction (action, data, index) {
                this.$notify({
                    group: 'notifications',
                    title: 'Important message',
                    text: 'Hello user! This is a notification!'
                });
            },

            route(){
                return this.$parent.$parent.$props.url + '/';
            },
        }
    }
</script>

<style>
    .table-actions{
        float: right;
        width: 90px;
    }

    .table-actions a{
        margin-left: 8px;
    }
</style>
