<script>
    import { Doughnut } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        props : {
            data : {
                required : true,
                type : Object,
            }
        },
        watch: {
            data: function (newData, oldData) {
                let labels = ['Mannen', 'Vrouwen'];

                let datasets = [
                        {
                            data : [
                                newData.male,
                                newData.female
                            ],
                            backgroundColor : ['rgb(114,102,186)', 'rgb(35,183,229)'],
                        }
                    ]

                this.renderChart(
                    {
                        labels: labels,
                        datasets: datasets
                    },
                    {
                        legend: {
                            position : 'right'
                        },
                    }
                )
            }
        },

    }
</script>