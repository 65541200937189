<template>
    <div>
        <div v-for="(reaction, index) in reactions">
            <p><strong>{{ reaction.writer.firstname }} {{ reaction.writer.lastname }}</strong></p>
            <p>{{ reaction.reaction }}</p>
            <p v-if="reaction.referenceWork !== null">
                <small>
                    <span class="s7-angle-right"></span>
                    <router-link :to="{name : 'works.show', params: {workId : reaction.referenceWork.id} }">Refereert: {{ reaction.referenceWork.name }}</router-link>
                </small>
            </p>
            <br />
        </div>
        <hr />
        <br />
        <h5>Voeg een reactie toe</h5>
        <form>
            <div class="form-group">
                <textarea v-model="reaction.reaction" rows="10" class="form-control"></textarea>
            </div>
            <div class="form-group">
                <label class="custom-control custom-checkbox">
                    <input type="checkbox" @click="loadWorks" v-model="referenceWork" class="custom-control-input is-valid">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">Refereer een ander werk</span>
                </label>
            </div>
            <div v-show="referenceWork" class="form-group">
                <label>Werk</label>
                <select class="form-control custom-select" v-model="reaction.referenceWork">
                    <option v-for="work in works" :value="work.id">{{ work.name }}</option>
                </select>
            </div>
            <button @click.prevent="sendForm" class="btn btn-success">Toevoegen</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                works : [],
                referenceWork : false,
                reaction : {
                    reaction : '',
                    referenceWork : null,
                },
                reactions : []
            }
        },
        props : {
            work : {
                required: true
            }
        },
        watch : {
            '$route' (newRoute, oldRoute){
                this.loadReactions()
            }
        },
        methods : {
            loadWorks(){
              if(this.works.length !== 0){
                  return;
              }

              axios.get('/works/list')
                  .then(response => {
                      this.works = response.data.data
                  })
                  .catch(error => {
                      this.$notifier.error()
                  })
            },
            loadReactions(){
                axios.get('/works/' + this.work + '/reactions')
                    .then(response => {
                        this.reactions = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error('Er ging iets mis!', 'De reacties van dit werk konden niet worden geladen');
                    })
            },
            resetForm(){
                this.referenceWork = false
                this.reaction.reaction = ''
                this.reaction.referenceWork = null
            },
            sendForm(){
                if(this.referenceWork === false){
                    this.reaction.referenceWork = null;
                }

                axios.post('/works/' + this.work + '/reactions', this.reaction)
                    .then(response => {
                        this.resetForm()
                        this.$notifier.success('Reactie toegevoegd');
                        this.loadReactions()
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            }
        },
        created(){
            this.loadReactions()
        }
    }
</script>

<style>

</style>