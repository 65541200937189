export default [
    {
        path: '/login',
        name: 'authentication.login',
        component: require('./authentication/LoginComponent.vue').default,
    },
    {
        path: '/register',
        name: 'authentication.register',
        component: require('./authentication/RegisterComponent.vue').default,
    },
    {
        path: '/forgot',
        name: 'authentication.forgot',
        component: require('./authentication/ForgotComponent.vue').default,
    },
    {
        path: '/reset/:token/:email?',
        name: 'authentication.reset',
        component: require('./authentication/ResetComponent.vue').default,
        props : true
    },
    {
        path : '/',
        component: require('./layouts/MasterLayoutComponent.vue').default,
        children : [
            //
            // Root
            //
            {
                path: '/',
                name: 'home',
                component: require('./groups/IndexComponent.vue').default,
            },

            //
            // Helps
            //
            {
                path: '/helps/',
                name: 'helps.index',
                component: require('./helps/IndexComponent.vue').default,
            },
            {
                path: '/helps/support',
                name: 'helps.support',
                component: require('./helps/SupportComponent.vue').default,
            },
            {
                path: '/helps/create',
                name: 'helps.create',
                component: require('./helps/EditorComponent.vue').default,
            },
            {
                path: '/helps/:helpId/edit',
                name: 'helps.edit',
                component: require('./helps/EditorComponent.vue').default,
                props: true,
            },
            {
                path: '/helps/:helpId/delete',
                name: 'helps.delete',
                component: require('./helps/DestroyComponent.vue').default,
                props: true,
            },
            {
                path: '/helps/:helpId',
                name: 'helps.show',
                component: require('./helps/IndexComponent.vue').default,
                props: true,
            },
            //
            // Sponsors
            //
            {
                path: '/sponsors/',
                name: 'sponsors.index',
                component: require('./sponsors/IndexComponent.vue').default,
            },
            {
                path: '/sponsors/overview',
                name: 'sponsors.overview',
                component: require('./sponsors/OverviewComponent.vue').default,
            },
            {
                path: '/sponsors/create',
                name: 'sponsors.create',
                component: require('./sponsors/EditorComponent.vue').default,
            },
            {
                path: '/sponsors/:sponsorId/edit',
                name: 'sponsors.edit',
                component: require('./sponsors/EditorComponent.vue').default,
                props: true,
            },
            {
                path: '/sponsors/:sponsorId/sponsors',
                name: 'sponsors.delete',
                component: require('./sponsors/DestroyComponent.vue').default,
                props: true,
            },
            {
                path: '/sponsors/:sponsorId',
                name: 'sponsors.show',
                component: require('./sponsors/ShowComponent.vue').default,
                props: true,
            },
            //
            // Users
            //
            {
                path: '/users/',
                name: 'users.index',
                component: require('./users/IndexComponent.vue').default,
            },
            {
                path: '/logout',
                name: 'authentication.logout',
                component: require('./authentication/LogoutComponent.vue').default,
            },
            {
                path: '/users/unapproved',
                name: 'users.unapproved',
                component: require('./users/IndexComponent.vue').default,
            },
            {
                path: '/users/create',
                name: 'users.create',
                component: require('./users/EditorComponent.vue').default,
            },
            {
                path: '/users/form',
                name: 'users.form',
                component: require('./users/FormComponent.vue').default,
            },
            {
                path: '/users/account',
                name: 'users.account',
                component: require('./users/AccountEditorComponent.vue').default,
            },
            {
                path: '/users/:userId/edit',
                name: 'users.edit',
                component: require('./users/EditorComponent.vue').default,
                props: true,
            },
            {
                path: 'users/:userId/delete',
                name: 'users.delete',
                component: require('./users/DestroyComponent.vue').default,
                props: true
            },
            {
                path: '/users/:userId',
                name: 'users.show',
                component: require('./users/ShowComponent.vue').default,
                props: true,
            },

            //
            // Conversations
            //

            {
                path: '/conversations/',
                name: 'conversations.index',
                component: require('./conversations/IndexComponent.vue').default,
            },
            {
                path: '/conversations/unread',
                name: 'conversations.unread',
                component: require('./conversations/IndexComponent.vue').default,
            },
            {
                path: '/conversations/create',
                name: 'conversations.create',
                component: require('./conversations/EditorComponent.vue').default,
            },
            {
                path: '/conversations/:conversationId',
                name: 'conversations.show',
                component: require('./conversations/ShowComponent.vue').default,
                props: true,
            },

            //
            // Works
            //

            {
                path: '/works/',
                name: 'works.index',
                component: require('./works/IndexComponent.vue').default,
            },
            {
                path: '/works/create',
                name: 'works.create',
                component: require('./works/EditorComponent.vue').default,
            },
            {
                path: '/works/unapproved',
                name: 'works.unapproved',
                component: require('./works/IndexComponent.vue').default,
            },
            {
                path: '/works/own',
                name: 'works.own',
                component: require('./works/IndexComponent.vue').default,
            },
            {
                path: '/works/:workId',
                name: 'works.show',
                component: require('./works/ShowComponent.vue').default,
                props: true
            },
            {
                path: '/works/:workId/delete',
                name: 'works.delete',
                component: require('./works/DestroyComponent.vue').default,
                props: true,
            },
            {
                path: '/works/:workId/edit',
                name: 'works.edit',
                component: require('./works/EditorComponent.vue').default,
                props: true
            },

            //
            // Groups
            //

            {
                path: '/groups/',
                name: 'groups.index',
                component: require('./groups/IndexComponent.vue').default,
            },
            {
                path: '/groups/create',
                name: 'groups.create',
                component: require('./groups/EditorComponent.vue').default,
            },
            {
                path: '/groups/:groupId/delete',
                name: 'groups.delete',
                component: require('./groups/DestroyComponent.vue').default,
                props: true,
            },
            {
                path: '/groups/:groupId',
                component: require('./groups/ShowComponent.vue').default,
                props: true,
                children: [
                    // Periods
                    {
                        path: 'periods',
                        name: 'periods',
                        component: require('./periods/IndexComponent.vue').default,
                        props: true
                    },
                    {
                        path: 'periods/create',
                        name: 'periods.create',
                        component: require('./periods/EditorComponent.vue').default,
                        props: true
                    },
                    {
                        path: 'periods/:periodId/delete',
                        name: 'periods.delete',
                        component: require('./periods/DestroyComponent.vue').default,
                        props: true
                    },
                    {
                        path: 'periods/:periodId',
                        name: 'periods.edit',
                        component: require('./periods/EditorComponent.vue').default,
                        props: true
                    },
                    // Participants
                    {
                        path: ':periodId/participants',
                        name: 'participants',
                        component: require('./participants/IndexComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/participants/create',
                        name: 'participants.create',
                        component: require('./participants/EditorComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/participants/:participantId/delete',
                        name: 'participants.delete',
                        component: require('./participants/DestroyComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/participants/:participantId/edit',
                        name: 'participants.edit',
                        component: require('./participants/EditorComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/participants/:participantId',
                        name: 'participants.show',
                        component: require('./participants/ShowComponent.vue').default,
                        props: true
                    },
                    // Days
                    {
                        path: ':periodId/days',
                        name: 'days',
                        component: require('./days/IndexComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/days/calendar',
                        name: 'days.calendar',
                        component: require('./days/CalendarComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/days/create',
                        name: 'days.create',
                        component: require('./days/EditorComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/days/:dayId/delete',
                        name: 'days.delete',
                        component: require('./days/DestroyComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/days/:dayId/edit',
                        name: 'days.edit',
                        component: require('./days/EditorComponent.vue').default,
                        props: true
                    },
                    {
                        path: ':periodId/days/:dayId',
                        name: 'days.show',
                        component: require('./days/ShowComponent.vue').default,
                        props: true
                    },
                    // Other
                    {
                        path: ':periodId?/',
                        name: 'groups.show',
                        component: require('./groups/OverviewComponent.vue').default,
                        props: true,
                    },
                    {
                        path: 'edit',
                        name: 'groups.edit',
                        component: require('./groups/EditorComponent.vue').default,
                        props: true
                    },
                ]
            },
        ]
    },


];