<template>
  <li class="dropdown nav-item mai-messages">
    <a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="nav-link dropdown-toggle">
      <span :class="iconClass"></span>
      <span v-if="data.length > 0" class="indicator"></span>
    </a>
    <ul class="dropdown-menu">
      <li>
        <div class="title">{{ name }}</div>
        <div class="mai-scroller">
          <div class="content">
            <ul v-if="data.length > 0">
              <li v-for="dataItem in data">
                <router-link :to="{name : route + '.show', params: constructParams(dataItem)}">
                  <div class="icon">
                    <span :class="iconClass"></span>
                  </div>
                  <div class="content">
                    <slot v-bind:item="dataItem"></slot>
                  </div>
                </router-link>
              </li>
            </ul>
            <ul v-else>
              <li>
                <a href="#">
                  <div class="content">
                    <span class="desc">Geen {{ name }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer">
          <router-link :to="{name : route + '.index'}">Bekijk alle {{ name }}</router-link>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    data: {
      required: true,
    }
  },
  computed: {
    iconClass: function () {
      return 'icon s7-' + this.icon
    },
    route: function () {
      return this.type + 's'
    },
    identifier: function () {
      return this.type + 'Id'
    }
  },
  methods: {
    constructParams: function (item) {
      let output = {}
      output[this.identifier] = item.id
      return output
    }
  }
}
</script>

<style>

</style>