<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <div v-if="uploading === false">
            <div v-if="value === null || value === ''">
                <input type="file"
                       class="inputfile"
                       @change="upload"
                       v-bind:name="name"
                       v-bind:id="name"
                />
                <label v-bind:for="name" class="btn btn-dark"> <i class="icon s7-upload"></i><span>Selecteer een bestand</span></label>
            </div>
            <div v-else>
                <div v-if="storagePath === undefined">
                    <button  @click.prevent="removeUpload" class="btn btn-danger"><i class="icon icon-left s7-close"></i></button> {{ value }}
                </div>
                <div v-else class="btn-group btn-space">
                    <button  @click.prevent="removeUpload" class="btn btn-danger"><i class="icon icon-left s7-close"></i></button>
                    <a target="_blank" :href="storagePath + value"  class="btn btn-dark">Bekijk : {{ value }}</a>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="progress mb-4">
                <div role="progressbar" v-bind:style="{width : uploadingPercentage + '%'}" class="progress-bar bg-success">Uploaden {{ uploadingPercentage }}%</div>
            </div>
        </div>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin],
        props : {
            url : {
                type: String,
                required : true,
            },
            maxSize : {
                type : Number,
            },
            storagePath : {
                // Path where file is stored, used for preview
                type: String,
                required : true,
            }
        },
        data(){
            return {
                uploading : false,
                uploadingPercentage : 0
            }
        },
        methods : {
            upload : function(event){
                let file = event.target.files[0]

                if(this.maxSize !== null){
                    // Bytes to megabytes
                    if(file.size / 1048576 > this.maxSize){
                        this.$notifier.error('Er ging iets mis!', 'Het bestand dat je wil uploaden is te groot')
                        return;
                    }
                }

                var data = new FormData();
                data.append('file', file);

                this.uploading = true;
                this.uploadingPercentage = 0;

                axios.post(this.url, data, {
                    onUploadProgress: progressEvent => {
                        this.uploadingPercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    }
                }).then(response => {
                    this.value = response.data.file
                    this.uploading = false;
                })
                .catch(error => {
                    if('errors' in error.response.data && 'file' in error.response.data.errors){
                        this.$notifier.error('Er ging iets mis bij het uploaden van het bestand!', error.response.data.errors.file.join(','))
                    }else{
                        this.$notifier.error('Er ging iets mis!', 'We konden het bestand niet uploaden!')
                    }
                    this.uploading = false;
                });
            },
            removeUpload : function(){
                this.value = null
            }
        }

    }
</script>

<style>

</style>