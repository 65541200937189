<template>
    <div class="row">
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-success">
                    <div class="tools">
                        <router-link class="icon" :to="{ name: 'sponsors.edit', params: { sponsorId: sponsor.id }}"><i class="s7-pen"></i></router-link>
                        <router-link class="icon" :to="{ name: 'sponsors.delete', params: { sponsorId: sponsor.id }}"><i class="s7-trash"></i></router-link>
                    </div>
                    <span class="title">{{ sponsor.name }}</span>
                </div>
                <div class="panel-body">
                    <p><strong>Leesgroepen</strong></p>
                    <div class="row" style="margin-bottom: 10px;">
                        <div v-for="group in sponsor.groups" class="col-md-4">
                            <router-link :to="{name: 'groups.show', params : {groupId:  group.id}}">{{ group.name }}</router-link> <span @click="detachGroup(group.id)" class="s7-close"></span>
                        </div>
                        <div v-if="sponsor.groups.length === 0" class="col-md-12">Geen</div>
                    </div>
                    <p><strong>Leesgroep toevoegen aan sponsor</strong></p>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <select class="form-control custom-select" v-model="newGroup">
                                    <option v-for="group in groups" :value="group">{{ group.name }}</option>
                                </select>
                            </div>
                            <button class="btn btn-space btn-success btn-xs" @click.prevent="attachGroup">Leesgroep Toevoegen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-border-color panel-border-color-success">
                <div class="panel-heading">Details</div>
                <div class="panel-body">
                    <img :src="sponsor.imageUrl" class="rounded img-fluid"/>
                    <p v-if="sponsor.url !== null"><br /><a :href="sponsor.url">Website</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sponsor: {
                    id : 0,
                    name : '',
                    url : '',
                    imageUrl : '',
                    groups : [],
                },
                groups : [],
                newGroup : {}
            }
        },
        props : {
            sponsorId : {
                required : true,
            }
        },
        watch : {
            '$route' (newRoute, oldRoute){
                this.loadSponsor(this.sponsorId)
            }
        },
        methods : {
            loadSponsor : function(id){
                axios.get('/sponsors/' + id)
                    .then(response => {
                        this.sponsor = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    });
            },
            loadGroups : function(){
                axios.get('/groups/list?filter[sponsor_id]=0')
                    .then(response => {
                        this.groups = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    });
            },
            attachGroup : function(){
                axios.get('/sponsors/' + this.sponsorId + '/attach/' + this.newGroup.id)
                    .then(response => {
                        this.sponsor.groups.push(this.newGroup)
                        this.groups = this.groups.filter(group => {
                            return group.id !== this.newGroup.id
                        });
                    })
                    .catch(error => {
                        if(error.response.status === 403){
                            this.$notifier.error('Er ging iets mis', error.response.data.message);
                        }else{
                            this.$notifier.error('Er ging iets mis', 'Gelieve opnieuw te proberen');
                        }
                    });
            },
            detachGroup : function(id){
                axios.get('/sponsors/' + this.sponsorId + '/detach/' + id)
                    .then(response => {
                        this.groups.push(this.sponsor.groups.find(group => {
                            return group.id === id
                        }))

                        this.sponsor.groups = this.sponsor.groups.filter(group => {
                            return group.id !== id
                        });
                    })
                    .catch(error => {
                        if(error.response.status === 403){
                            this.$notifier.error('Er ging iets mis', error.response.data.message);
                        }else{
                            this.$notifier.error('Er ging iets mis', 'Gelieve opnieuw te proberen');
                        }
                    });
            }

        },
        created(){
            this.loadSponsor(this.sponsorId);
            this.loadGroups();
        }
    }
</script>

<style>

</style>