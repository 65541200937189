<template>
    <div v-if="disabled !== false">
        <input class="range-slider" type="range" ref="rangePicker" min="1" max="5" :value="value" @input="updateRange()">
        <span class="range-info">{{ levels }}</span>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props : {
            value : {
                required : true
            },
            disabled : {
                default: false,
                type: Boolean
            }
        },
        methods : {
            updateRange() {
                this.$emit('input', parseInt(this.$refs.rangePicker.value))
            }
        },
        computed : {
            levels : function(){
                if(this.value === 1){
                    return 'Zwak'
                }else if(this.value === 2){
                    return 'Eerder Zwak'
                }else if(this.value === 3){
                    return 'Gemiddeld'
                }else if(this.value === 4){
                    return 'Eerder Goed'
                }else if(this.value === 5){
                    return 'Goed'
                }else{
                    return ''
                }
            }
        }
    }
</script>

<style>
    .range-info{
        font-size: 10px;
    }
</style>