<template>
    <nav class="navbar navbar-expand-lg mai-sub-header">
        <div class="container">
            <nav class="navbar navbar-expand-md">
                <button type="button" data-toggle="collapse" data-target="#mai-navbar-collapse" aria-controls="#mai-navbar-collapse" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler hidden-md-up collapsed">
                    <div class="icon-bar">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div id="mai-navbar-collapse" class="navbar-collapse collapse mai-nav-tabs">
                    <ul class="nav navbar-nav">
                        <slot></slot>
                    </ul>
                </div>
            </nav>
        </div>
    </nav>
</template>

<script>
    export default {

    }
</script>

<style>

</style>