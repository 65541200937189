var BasicFieldMixin = {
    props: {
        title : {
            type : String,
            required : false,
            default : ''
        },
        description: {
            type : String,
            required : false,
            default : ''
        },
        name: {
            type : String,
            required : true
        },
        id: {
            type: Number,
            required: false,
            default : null
        }
    },
    inject : ['fieldStore'],
    methods : {
        updateValue : function(value){
            this.fieldStore.setValue(value, this.name, this.id)
        }
    },
    created(){
        this.fieldStore.register(this.name, this.id)
    },
    computed: {
        value: {
            get () {
                return this.fieldStore.value(this.name, this.id)
            },
            set (value) {
                this.fieldStore.setValue(value, this.name, this.id)
            }
        },
        error: function() {
            return this.fieldStore.error(this.name, this.id)
        },
        hasError : function (){
            return this.fieldStore.hasError(this.name, this.id)
        },
    },
    /*
    computed: {
        value: {
            get () {
                return this.$store.getters.value({
                    name : this.name,
                    identifier : this.id
                })
            },
            set (value) {
                this.$store.commit('setValue', {
                    name : this.name,
                    identifier : this.id,
                    value : value
                })
            }
        },
        error: function() {
            return this.$store.getters.error({
                name : this.name,
                identifier : this.id
            })
        },
        hasError : function (){
            return this.$store.getters.hasError({
                name : this.name,
                identifier : this.id
            })
        },
    },
    methods : {
        updateValue : function(value){
            this.$store.commit('setValue', {
                name : this.name,
                identifier : this.id,
                value : value
            })
        }
    },
    created(){
        this.$store.commit('register', {
            name : this.name,
            identifier : this.id
        })
    }
    */
    /*
    methods : {
        isFormer : function(){
            return false
        },
        getFormer : function(){
            return this.$parent.isFormer() === true ? this.$parent : this.$parent.getFormer()
        },
        updateValue : function(value){
            this.getFormer().fieldStore.setValue(value, this.name, this.id)
        }
    },
    created(){
        this.getFormer().fieldStore.register(this.name, this.id)
    },
    computed: {
        value: {
            get () {
                this.getFormer().fieldStore.value(this.name, this.id)
            },
            set (value) {
                this.getFormer().fieldStore.setValue(value, this.name, this.id)
            }
        },
        error: function() {
            this.getFormer().fieldStore.error(this.name, this.id)
        },
        hasError : function (){
            this.getFormer().fieldStore.hasError(this.name, this.id)
        },
    },
    */
}

export  {BasicFieldMixin}