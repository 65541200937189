<template>
    <div>
        <nav-bar :username="$permissioner.user.firstname + ' ' + $permissioner.user.lastname">
            <template slot="dropdowns">
                <nav-bar-dropdown v-if="$permissioner.can('list conversations')" name="Berichten" icon="chat" type="conversation" :data="newConversations">
                    <template slot-scope="props">
                        <span class="date">{{ props.item.updated | formatDate }}</span>
                        <span class="name">{{ props.item.correspondent.name }}</span>
                        <span class="desc">{{ props.item.snippet }}</span>
                    </template>
                </nav-bar-dropdown>
                <nav-bar-dropdown v-if="$permissioner.can('approve works')" name="Werken" icon="culture" type="work" :data="newWorks">
                    <template slot-scope="props">
                        <span class="date">{{ props.item.type }}</span>
                        <span class="name">{{ props.item.name }}</span>
                        <span class="desc">{{ props.item.author }}</span>
                    </template>
                </nav-bar-dropdown>
                <nav-bar-dropdown v-if="$permissioner.can('approve users')" name="Gebruikers" icon="users" type="user" :data="newUsers">
                    <template slot-scope="props">
                        <span class="name"><strong>{{ props.item.firstname }} {{ props.item.lastname }}</strong></span>
                        <span class="desc">{{ props.item.email }}</span>
                    </template>
                </nav-bar-dropdown>
            </template>
            <template slot="menu">
                <nav-bar-menu-item name="Mijn Account" icon="home" route="users.account"></nav-bar-menu-item>
                <nav-bar-menu-item name="Uitloggen" icon="power" route="authentication.logout"></nav-bar-menu-item>
            </template>
        </nav-bar>
        <div class="mai-wrapper">
            <menu-container>
                <menu-group v-if="$permissioner.can('fill form')"  name="Formulier" icon="note2" route="users">
                    <menu-item v-if="$permissioner.can('fill form')" name="Formulier" icon="note2" route="form"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('create groups')"  name="Leesgroepen" icon="notebook" route="groups" active>
                    <menu-item v-if="$permissioner.can('create groups')" name="Overzicht" icon="notebook" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('create groups')" name="Leesgroep Toevoegen" icon="plus" route="create"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('list sponsors')"  name="Sponsors" icon="wallet" route="sponsors">
                    <menu-item v-if="$permissioner.can('list sponsors')" name="Overzicht" icon="wallet" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('list sponsors')" name="Leesgroepen" icon="menu" route="overview"></menu-item>
                    <menu-item v-if="$permissioner.can('create sponsors')" name="Sponsor Toevoegen" icon="plus" route="create"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('list works')"  name="Bibliotheek" icon="culture" route="works">
                    <menu-item v-if="$permissioner.can('list works')" name="Alle Werken" icon="culture" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('list works')" name="Mijn Werken" icon="albums" route="own"></menu-item>
                    <menu-item v-if="$permissioner.can('approve works')" name="Goed te keuren Werken" icon="way" route="unapproved"></menu-item>
                    <menu-item v-if="$permissioner.can('create works')" name="Werk Toevoegen" icon="plus" route="create"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('list conversations')"  name="Berichten" icon="chat" route="conversations">
                    <menu-item v-if="$permissioner.can('list conversations')" name="Mijn Berichten" icon="drawer" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('list conversations')" name="Ongelezen Berichten" icon="box1" route="unread"></menu-item>
                    <menu-item v-if="$permissioner.can('list conversations')" name="Nieuw Bericht" icon="note" route="create"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('list users')" name="Gebruikers" icon="users" route="users">
                    <menu-item v-if="$permissioner.can('list users')" name="Alle gebruikers" icon="user" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('approve users')" name="Te Activeren Gebruikers" icon="way" route="unapproved"></menu-item>
                    <menu-item v-if="$permissioner.can('create users')" name="Gebruiker Toevoegen" icon="plus" route="create"></menu-item>
                </menu-group>
                <menu-group v-if="$permissioner.can('list helps')"  name="Help" icon="help2" route="helps">
                    <menu-item v-if="$permissioner.can('list helps')" name="Handleiding" icon="help2" route="index"></menu-item>
                    <menu-item v-if="$permissioner.can('list helps')" name="Download" icon="cloud-download" :route="helpPdfRoute" directRoute></menu-item>
                    <menu-item v-if="$permissioner.can('list helps')" name="Meld Probleem" icon="help1" route="support"></menu-item>
                    <menu-item v-if="$permissioner.can('create helps')" name="Nieuwe Pagina" icon="plus" route="create"></menu-item>
                </menu-group>
            </menu-container>
            <div class="main-content container" id="app">
            <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';
    import Cookies from 'js-cookie';

    import MenuContainerComponent from './menu/MenuContainerComponent.vue'
    import MenuGroupComponent from './menu/MenuGroupComponent.vue'
    import MenuItemComponent from './menu/MenuItemComponent.vue'
    import NavBarContainerComponent from './navbar/NavBarContainerComponent.vue'
    import NavBarDropDownComponent from './navbar/NavBarDropDownComponent.vue'
    import NavBarMenuItemComponent from './navbar/NavBarMenuItemComponent.vue'



    export default {
        components : {
            'menu-container': MenuContainerComponent,
            'menu-group': MenuGroupComponent,
            'menu-item': MenuItemComponent,
            'nav-bar' : NavBarContainerComponent,
            'nav-bar-dropdown' : NavBarDropDownComponent,
            'nav-bar-menu-item' : NavBarMenuItemComponent,
        },
        data() {
            return {
                newConversations : [],
                newWorks : [],
                newUsers : [],
            }
        },
        filters: {
            formatDate: function (value) {
                let fmt = 'D MMMM YYYY HH:mm'
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            }
        },
        computed : {
            helpPdfRoute : function(){
                return '/api/helps/pdf?token=' + this.$parent.accessToken
            }
        },
        created(){
            // When the cookie is set but access token not(e.g. reload of page) make sure the user is authenticated
            if(Cookies.get('access_token') !== undefined && this.$parent.authenticated() === false){
                let expiring =  parseInt(Cookies.get('expiring')) - moment().unix() + 60

                console.log('New calculated expiring', expiring, ' seconds')
                this.$parent.setAccessToken(Cookies.get('access_token'), expiring)

                axios.get('/authentication/me').then(response => {
                    this.$permissioner.user = response.data.user
                    window.permissioner = this.$permissioner
                });
            }

            // If the user is not authenticated redirect to the login page
            if(this.$parent.authenticated() === false){
                this.$router.push({name : 'authentication.login'})
            }

            // Load notifications
            axios.get('/system/notifications')
                .then(response => {
                    this.newConversations = response.data.data.conversations
                    this.newWorks = response.data.data.works
                    this.newUsers = response.data.data.users

                }).catch(error =>  {
                    this.$notifier.error()
                });
        }
    }
</script>

<style>

</style>