<template>
    <former :config="config" @form-success="formSuccess">
        <div class="row">
            <div class="col-md-6">
                <field-string title="Titel" name="title"></field-string>
            </div>
            <div class="col-md-6">
                <field-select2 multiple title="Rollen" name="roles" :options="roleOptions"></field-select2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <field-wysiwyg title="Tekst" name="content"></field-wysiwyg>
            </div>
            <div class="col-md-12">
                <field-icon title="Pictogram" name="icon"></field-icon>
            </div>
        </div>
    </former>
</template>

<script>
    export default {
        data() {
            return {
                config: {

                },
                roleOptions : []
            }
        },
        created() {
            axios.get('/users/roles')
                .then(response => {
                    this.roleOptions = response.data.data.map(role => {
                        return {id : role.id, name: role.name}
                    })
                })
                .catch(error => {
                    this.$notifier.error()
                })

            if(this.$route.name === 'helps.create'){
                this.config = {
                    submitText: 'Toevoegen',
                    messageSuccessTitle: 'Help Pagina Toegevoegd!',
                    url: '/helps',
                    update: false
                }
            }else if(this.$route.name === 'helps.edit'){
                this.config = {
                    id: this.helpId,
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Help Pagina Gewijzigd!',
                    url: '/helps',
                    update: true
                }
            }else{
                this.$notifier.error('Ongekende route!')
            }
        },
        props : {
            helpId : {
                required : false
            },
        },
        methods : {
            formSuccess : function(){
                if(this.helpId === undefined){
                    this.$router.push({name : 'helps.index'})
                }else{
                    this.$router.push({name : 'helps.show', params : {helpId : this.helpId}})
                }
            }
        }
    }
</script>

<style>

</style>