<template>
    <li class="timeline-item">
        <div class="timeline-date"><span>Nieuw Bericht</span></div>
        <div class="timeline-content">
            <div class="timeline-header">
                <div class="timeline-summary">
                    <vue-editor v-model="message"
                                id="editor"
                                placeholder="Typ een nieuw bericht hier"
                                :editorToolbar="customToolbar"
                                style="margin-top: 10px;"
                    ></vue-editor>
                    <button style="margin-top: 10px;" @click.prevent="send" class="btn btn-space btn-success btn-xs"> <i class="icon icon-left s7-paper-plane"></i> Verzenden</button>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import { VueEditor } from 'vue2-editor'

    export default {
        components: {
            VueEditor
        },
        data() {
            return {
                message: '',
                customToolbar: [
                    [{ 'header': [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    ['link', 'blockquote'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'align': [] }],
                ]
            }
        },
        props : {
            conversation : {
                required : true,
                type : Number
            },
            recipient : {
                required : true,
                type : Number
            }
        },
        methods : {
            send : function(){
                if(this.message === ''){
                    this.$notifier.error('Bericht vereist', 'Gelieve eerst een bericht te typen');
                }else{
                    axios({
                        url : '/conversations/' + this.conversation,
                        method : 'PUT',
                        data : {
                            message : this.message,
                            recipient : this.recipient
                        }
                    })
                        .then(response =>{
                            this.$notifier.success('Bericht verzonden');
                            this.$emit('message-send', this.message)
                            this.message = ''
                        })
                        .catch(error =>{
                            this.$notifier.error()
                        })
                }
            }
        }
    }
</script>

<style>

</style>