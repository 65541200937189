<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="panel panel-default panel-table">
                <div class="filters">
                    <div class="filters-title">
                        Leesdagen
                    </div>
                    <div class="filters-content d-flex flex-row justify-content-between d-flex align-items-center">
                        <div class="p-2">
                            <router-link :to="{ name: 'days.calendar', params: { periodId: periodId }}"><span class="s7-date"></span> Download Kalender</router-link>
                        </div>
                        <div class="p-2">
                            <router-link class="btn btn-rounded btn-space btn-success" :to="{ name: 'days.create', params: { periodId: periodId }}">Toevoegen</router-link>
                        </div>
                    </div>
                </div>
                <hr style="margin-top: 3px;margin-bottom: 3px;" />
                <div class="panel-body">
                    <tabler :api-url="apiUrl" :initial-sort="initialSort" :fields="fields" :history="false">
                        <template slot="actions" slot-scope="props">
                            <div class="table-actions">
                                <router-link class="icon" :to="{ name: 'days.show', params: { periodId: periodId, dayId: props.rowData.id }}"><i class="s7-look"></i></router-link>
                                <router-link class="icon" :to="{ name: 'days.edit', params: { periodId: periodId, dayId: props.rowData.id }}"><i class="s7-pen"></i></router-link>
                                <router-link class="icon" :to="{ name: 'days.delete', params: { periodId: periodId, dayId: props.rowData.id }}"><i class="s7-trash"></i></router-link>
                            </div>
                        </template>
                    </tabler>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fields: [
                    {
                        name : 'date',
                        title : 'Dag',
                        sortField: 'date',
                        callback: 'formatDate|D MMMM YYYY HH:mm'
                    },
                    {
                        name : 'participants',
                        title : 'Participanten',
                    },
                    {
                        name: '__slot:actions',
                        title: '',
                        dataClass: 'actions float-right'
                    }
                ],
                initialSort : [
                    {
                        field: 'date',
                        direction: 'asc'
                    }
                ]
            }
        },
        props : {
            groupId : {
                required : true
            },
            periodId : {
                required: true,
            }
        },
        computed : {
            apiUrl :  function(){
                return '/groups/' + this.groupId + '/periods/' + this.periodId + '/days';
            }
        },
        methods: {
            onAction (action, data, index) {
                console.log('slot action: ' + action, data.id, index)
            },
        }
    }
</script>

<style>

</style>