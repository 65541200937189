<template>
    <former :config="config" v-on:form-success="formSuccess" v-if="completed === false">
        <h5>Begeleiding</h5>
        <div class="row">
            <div class="col-md-12">
                <field-select title="Leesbegeleiding" name="when" :options="{'binnen werkuren':'Binnen De Werkuren', 'vrijwilliger': 'Als Vrijwilliger'}"></field-select>
            </div>
        </div>
        <h5>Praktijk</h5>
        <div class="row">
            <div class="col-md-4">
                <field-number title="Frequentie van begeleiding" name="frequency"></field-number>
            </div>
            <div class="col-md-4">
                <field-number title="Verwacht aantal leesbijeenkomsten/jaar (min. 20)"  name="reading_days_frequency"></field-number>
            </div>
            <div class="col-md-4">
                <field-switch title="Ben je verzekerd door de organisatie waarvoor je leest?" name="is_insured"></field-switch>
            </div>
        </div>
        <h5>Opleiding</h5>
        <div class="row">
            <div class="col-md-6">
                <field-select title="Wanneer verkies je opleidingsdagen?" name="education_days_preference" :options="{'weekend':'Tijdens het weekend', 'week': 'Tijdens de week'}"></field-select>
            </div>
            <div class="col-md-6">
                <field-select title="Wanneer verkies je andere bijeenkomsten? " name="reading_days_preference" :options="{'weekend':'Tijdens het weekend', 'week': 'Tijdens de week'}"></field-select>
            </div>
            <div class="col-md-12">
                <field-text title="Reeds gevolgde opleiding(en):" name="followed_educations"></field-text>
            </div>
        </div>
        <h5>Persoonlijk</h5>
        <div class="row">
            <div class="col-md-4">
                <field-select title="Een hart hebben voor verhalen:" name="story_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="Een hart hebben voor poezie:" name="poetry_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="Een hart hebben voor mensen:" name="people_skill" :options="skillOptions"></field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <field-select title="Goed kunnen luisteren:" name="listening_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="Eigen inzichten en overtuigingen kunnen bijstellen:" name="open_minded_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="In staat zijn om groepsprocessen te begeleiden:" name="supervising_skill" :options="skillOptions"></field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <field-select title="Planningen, afspraken en problemen correct opvolgen:" name="planning_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="Zelfstandig en zelfredzaam kunnen werken:" name="independent_skill" :options="skillOptions"></field-select>
            </div>
            <div class="col-md-4">
                <field-select title="Goed kunnen communiceren:" name="communication_skill" :options="skillOptions"></field-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <field-select title="Respect tonen voor de deelnemer en de noden van de groep:" name="respect_skill" :options="skillOptions"></field-select>
            </div>
        </div>
        <h5>Interesses</h5>
        <div class="row">
            <div class="col-md-12">
                <field-text title="Schrijf je graag eens over je leesplek/ervaring?" name="litterateur"></field-text>
            </div>
            <div class="col-md-12">
                <field-text title="Maak je graag foto's?" description="altijd toestemming vragen" name="photographer"></field-text>
            </div>
        </div>
        <h5>Missie</h5>
        <field-text title="Wat spreekt jou zo aan bij het Lezerscollectief? " name="appeal_to_us"></field-text>
    </former>
    <div v-else>
        <h1>Formulier ingevuld!</h1>
        <p>Hallo,</p>
        <p>Dank je voor het invullen van ons kennismakingsformulier.</p>
        <p>We zijn momenteen je registratie aan het bekijken.</p>
        <p>Je hoort snel iets van ons!</p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    submitText: 'Insturen',
                    messageSuccessTitle: 'Formulier Toegevoegd!',
                    storeUrl: '/users/form',
                },
                skillOptions : {
                    1: 'Zwak',
                    2: 'Eerder Zwak',
                    3: 'Gemiddeld',
                    4: 'Eerder Sterk',
                    5: 'Sterk'
                },
                completed : false,
            }
        },
        methods : {
            formSuccess : function(payload){
                this.completed = true
            }
        },
    }
</script>

<style>

</style>