<template>
    <div>
        <input v-if="isLoading === false" type="submit"
               v-bind:value="description"
               class="btn btn-success"
               v-on:click.prevent="sendForm"
        >
        <button v-else class="loadbtn btn btn-success" disabled>
            <div class="loader"></div><div class="text">Laden</div>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            description : {
                type: String,
                required : true,
            },
            isLoading : {
                type : Boolean,
                required : true,
                default : false
            }
        },
        methods : {
            sendForm : function(event){
                this.$emit('send');
            }
        }
    }
</script>

<style>
    .loadbtn .loader {
        border: 2px solid #fff;
        border-top: 2px solid #333;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
        float: left;
    }

     @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .loadbtn .text{
        margin-left: 5px;
        margin-top: 2px;
        float: left;
    }
</style>
