<template>
    <div class="row" id="works">
        <div class="col-lg-8">
            <div class="panel">
                <div class="panel-body">
                    <pdfer v-if="work.format === 'file'" :src="work.fileUrl"></pdfer>
                    <div v-else v-html="work.content"></div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <work-approve-box v-if="work.is_public === false && $permissioner.can('approve works')" :work="workId"></work-approve-box>
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-success">
                    <div class="tools">
                        <router-link v-if="$permissioner.can('update works', work.user_id)" class="icon" :to="{ name: 'works.edit', params: { workId: work.id  }}"><i class="s7-pen"></i></router-link>
                        <router-link v-if="$permissioner.can('delete works', work.user_id)"  class="icon" :to="{ name: 'works.delete', params: { workId: work.id }}"><i class="s7-trash"></i></router-link>
                    </div>
                    <span class="title">{{ work.name }}</span>
                    <span class="panel-subtitle">{{ work.author }}</span>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-12" v-if="work.description !== ''">
                            <h5><i class="icon s7-albums"></i> Beschrijving</h5>
                            <p class="text-muted" v-html="work.description"></p>
                        </div>
                        <div class="col-sm-6" v-if="work.type !== null">
                            <h5><i class="icon s7-albums"></i> Soort</h5>
                            <p class="text-muted">{{ work.type }}</p>
                        </div>
                        <div class="col-sm-6" v-if="work.audience !== null">
                            <h5><i class="icon s7-date"></i> Leeftijd</h5>
                            <p class="text-muted">{{ work.audience }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="work.categories.length !== 0">
                        <div class="col-sm-12">
                            <h5><i class="icon s7-menu"></i> Categorieën</h5>
                            <p v-for="category in work.categories" class="text-muted">{{ category.name }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 align-self-center">
                            <small class="text-muted">Door: {{ work.owner.firstname }} {{ work.owner.lastname }}</small>
                        </div>
                        <div class="col-sm-6 align-self-center">
                            <like-button class="btn btn-rounded btn-space btn-secondary float-right" :work="workId" v-model="work.liked"></like-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-border-color panel-border-color-success">
                <div class="panel-heading">Reacties</div>
                <div class="panel-body">
                    <work-reactions :work="workId"></work-reactions>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PDFer from '../../components/pdfer.vue'
    import WorkReactions from './ReactionsComponent.vue'
    import WorkApproveBox from './ApproveBoxComponent.vue'
    import LikeButton from './LikeButtonComponent.vue'

    export default {
        components : {
            'pdfer': PDFer,
            'work-reactions': WorkReactions,
            'work-approve-box': WorkApproveBox,
            'like-button': LikeButton
        },
        data() {
            return {
                work: {
                    id : 0,
                    name : '',
                    author : '',
                    format : '',
                    file : '',
                    content : '',
                    type : '',
                    audience : '',
                    categories : [],
                    owner : {
                        firstname : '',
                        lastname : ''
                    },
                    liked : false,
                    is_public : false,

                }
            }
        },
        props : {
            workId : {
                required : true,
            }
        },
        watch : {
            '$route' (newRoute, oldRoute){
                this.loadWork(this.workId)
            }
        },
        methods : {
            loadWork : function(id){
                axios.get('/works/' + id)
                    .then(response => {
                        this.work = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    });
            }
        },
        created(){
            this.loadWork(this.workId);
        }
    }
</script>

<style>

</style>