<template>
    <destroy-view type="Periode" :apiUrl="apiUrl" @destroy-success="destroySuccess"></destroy-view>
</template>

<script>
    import DestroyComponent from '../layouts/DestoryComponent.vue'

    export default {
        components : {
            'destroy-view' : DestroyComponent
        },
        props : {
            groupId : {
                required : true
            },
            periodId : {
                required : true
            },
        },
        computed : {
            apiUrl : function () {
                return '/groups/' + this.groupId + '/periods/' + this.periodId
            }
        },
        methods : {
            destroySuccess : function(){
                console.log('destroy')
                this.$parent.switchPeriod()
            }
        }
    }
</script>

<style>

</style>