<template>
    <div>
        <h1>Uitloggen</h1>
        <p>Weet u zeker dat u wilt uitloggen?</p>
        <button class="btn btn-danger" @click.prevent="logout">Uitloggen</button>
    </div>
</template>

<script>
    export default {
        methods : {
            'logout' : function(){
                this.$parent.$parent.logout()
                this.$router.push({name : 'authentication.login'})
            }
        }
    }
</script>

<style>

</style>