<template>
    <div>
        <span class="table-filter-title">{{ title }}</span>
        <div v-if="flat" class="filter-container">
            <div class="custom-controls-stacked" style="margin-top: 10px;">
                <label class="custom-control custom-radio">
                    <input @change="sendFilter" type="radio" v-model="selected" :name="filter"  :value="null" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">Alle</span>
                </label>
                <label class="custom-control custom-radio" v-for="(option, name) in options">
                    <input @change="sendFilter" type="radio" v-model="selected" :name="filter" :value="name" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{ option }}</span>
                </label>
            </div>
        </div>
        <div v-else class="filter-container">
            <select v-model="selected" class="form-control custom-select" @change="sendFilter">
                <option :value="null" selected>Alle</option>
                <option v-for="(option, name) in options" v-bind:value="name">
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            title: {
                type : String,
                required : true
            },
            filter: {
                type : String,
                required : true
            },
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            flat: {
                type : Boolean,
                default : false
            },
            url : {
                type: String,
                default : ''
            }
        },
        data () {
            return {
                selected: null,
            }
        },
        methods: {
            sendFilter () {
                let data = this.selected !== null ? [this.selected] : [];

                this.$events.fire('filter', {
                    filter : this.filter,
                    selected : data
                });
            },
        },
        mounted (){
            this.$events.$on('set-filter' , eventData => {
                if(this.filter in eventData){
                    this.selected = eventData[this.filter][0]
                }
            })
        },
    }
</script>