g<template>
    <div>
        <div class="form-group">
            <label class="control-label">{{ title }}</label>
            <p v-if="description">{{ description }}</p>
            <div class="radio" v-for="(item, index) in panes">
                <label class="custom-control custom-radio">
                    <input type="radio"
                           v-bind:name="name"
                           class="custom-control-input"
                           v-bind:class="{'is-invalid': hasError}"
                           v-bind:value="item.name"
                           v-model="value"
                    >
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{ item.title }}</span>
                </label>
            </div>
        </div>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
        <slot></slot>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin],
        data: function(){
            return {
                panes : []
            }
        },
        mounted() {
            this.panes = this.$children
        }
    }
</script>

<style>

</style>