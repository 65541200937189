<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <flat-pickr
                class="form-control"
                v-model="value"
                :placeholder="title"
                :class="{'is-invalid': hasError}"
                :config="config"
        ></flat-pickr>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import 'flatpickr/dist/themes/airbnb.css';
    import {Dutch} from 'flatpickr/dist/l10n/nl'


    export default {
        mixins : [BasicFieldMixin],
        components: {
            flatPickr
        },
        data : function(){
            return {
                config: {
                    time_24hr: true,
                    minuteIncrement: 10,
                    altFormat: 'j F Y - H:i',
                    altInput: true,
                    dateFormat: 'U',
                    locale: Dutch,
                    enableTime: true,
                },
            }
        },

    }
</script>

