<template>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-dark">
                    <div class="tools"><span class="icon s7-attention"></span></div>
                    <span class="title">{{ type }} Verwijderen</span>
                </div>
                <div class="panel-body">
                    <p>{{ text }}</p>
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button class="btn btn-success" @click="back">Terug</button>
                        <button class="btn btn-danger" @click="destroy">Verwijder {{ type }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                msg: 'Hello world!'
            }
        },
        props : {
            type : {
                type : String,
                required : true,
            },
            apiUrl : {
                type : String,
                required : true,
            },
            backRoute : {
                type: String,
                required: false,
            },
            name : {
                type: String,
                required: false,
            }
        },
        computed : {
            text : function(){
                return this.name === undefined ?
                    'Deze handeling kan niet ongedaan gemaakt worden. Weet u zeker dat u wilt doorgaan?' :
                    'Deze handeling kan niet ongedaan gemaakt worden. Weet u zeker dat u ' + this.name + ' wilt verwijderen?'
            }
        },
        methods : {
            destroy : function(){
                axios.delete(this.apiUrl)
                    .then(response => {
                        this.$notifier.success(this.type + ' Verwijderd!')
                        this.backRoute !== undefined ?
                            this.$router.push({name : this.backRoute}) :
                            this.$router.go(-1);

                        this.$emit('destroy-success');
                    }).catch(error => {
                        if(error.response.status === 422){
                            this.$notifier.error('Er ging iets mis!', error.response.data.error)
                        }else{
                            this.$notifier.error('Er ging iets mis, gelieve opnieuw te proberen.')
                        }
                    })
            },
            back : function(){
                this.$router.back()
            }
        }
    }
</script>

<style>

</style>