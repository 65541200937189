<template>
    <div v-show="isActive" class="tab-content">
        <div id="home" role="tabpanel" class="tab-pane active">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props : {
            name : {
                type: String,
                required: true
            },
            title : {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                isActive : false
            }
        }
    }
</script>

<style>

</style>