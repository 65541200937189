<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <div class="radio" v-for="(item, index) in options">
            <label class="custom-control custom-radio">
                <input type="radio"
                       v-bind:name="name"
                       v-bind:value="index"
                       class="custom-control-input"
                       v-model="value">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{ item }}</span>
            </label>
        </div>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {OptionsFieldMixin} from '../../mixins/OptionsFieldMixin.js'

    export default {
        mixins : [OptionsFieldMixin]
    }
</script>
