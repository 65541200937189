<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <select v-model="value" v-bind:name="name" class="form-control custom-select">
            <option v-for="(item, index) in options"
                    :value="index"
                    v-bind:class="{'is-invalid': hasError}"
            >{{ item }}</option>
        </select>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {OptionsFieldMixin} from '../../mixins/OptionsFieldMixin.js'

    export default {
        mixins : [OptionsFieldMixin],
    }
</script>
