<template>
    <div class="col-md-12 email-content">
        <div class="email-inbox-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="email-title">
                        <span :class="icon"></span> {{ title }}
                        <span v-if="unreadCount > 0" class="new-messages">{{ unreadCount }} ongelezen berichten</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="email-list" v-if="conversations.length > 0">
            <router-link
                    class="email-list-item"
                    :key="conversation.id"
                    :class="{'email-list-item-unread' : conversation.unread}"
                    v-for="conversation in conversations"
                    :to="{name : 'conversations.show', params : {conversationId : conversation.id}}"
            >
                <div class="email-list-detail"><span class="from">{{ conversation.correspondent.name }}</span>
                    <p class="msg">{{ conversation.snippet }}</p><span class="date"><i class="icon s7-chat"></i> {{ conversation.updated | formatDate }}</span>
                </div>
            </router-link>
        </div>
        <div class="email-list" v-else>
            <div role="alert" class="alert alert-theme alert-dark alert-dismissible">
                <div class="icon"><span class="s7-info"></span></div>
                <div class="message">Geen berichten</div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                conversations : []
            }
        },
        methods : {
            loadConversations(){
                axios.get(this.apiUrl)
                    .then(response => {
                        this.conversations = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            }
        },
        computed : {
            unreadCount : function(){
                return this.conversations.reduce(function (sum, conversation) {
                    return conversation.unread === true ? sum + 1 : sum;
                }, 0)
            },
            apiUrl : function(){
                let apiUrl = '/conversations';
                if(this.$route.name === 'conversations.unread'){
                    apiUrl += '?filter[unread]=true'
                }

                return apiUrl
            },
            title : function(){
                return this.$route.name === 'conversations.unread' ? 'Ongelezen Berichten' : 'Berichten'
            },
            icon : function(){
                return this.$route.name === 'conversations.unread' ? 'icon s7-box1' : 'icon s7-drawer'
            }
        },
        watch : {
            '$route' : 'loadConversations'
        },
        filters : {
            formatDate: function (value) {
                let fmt = 'D MMMM YYYY HH:mm'
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            }
        },
        created() {
            this.loadConversations()
        }
    }
</script>

<style>

</style>