<template>
    <div class="filter-container">
        <div class="input-group mb-2">
            <input placeholder="Zoek ..." type="text" v-model="searchString" @input="updateSearch" class="form-control" ><span class="input-group-btn">
            <button type="button" class="btn btn-primary" @click="search" @keyup.enter="search"><i class="s7-search"></i></button></span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                searchString: ''
            }
        },
        methods: {
            search () {
                this.$events.fire('search', this.searchString);
            },
            updateSearch() {
                if(this.searchString === ''){
                    this.search()
                }
            }
        },
        mounted (){
            this.$events.$on('set-search' , eventData => {
                this.searchString = eventData
            })
        },
    }
</script>