import {BasicFieldMixin} from "./BasicFieldMixin";

var OptionsFieldMixin = {
    mixins : [BasicFieldMixin],
    props: {
        options: {
            type : [Object, Array],
            required : false
        }
    },
}

export  {OptionsFieldMixin}