<template>
    <form>
        <input type="hidden" name="_token" v-bind:value="csrfToken">
        <slot></slot>
        <send :description="config.submitText" :is-loading="isLoading" @send="sendForm"></send>
    </form>
</template>

<script>
    import _ from 'lodash'
    import FieldStore from '../storage/FieldStore'

    export default {
        data: function (){
            return {
                csrfToken: '',
                isLoading: false,
                formEndpoint : '',
                dataEndpoint : '',
                method : '',
                defaultConfig : {
                    submitText: 'Verzenden', // Text to the submit button
                    messageSuccessTitle: 'Gelukt', // Message when form submission was success
                    messageSuccess : '',
                    url: '', // API endpoint to send to
                    updateUrl : null, // API endpoint to update the resource(will overwrite url)
                    storeUrl : null, // API endpoint to create the resource(will overwrite url)
                    dataUrl: null, // API endpoint to fetch data from
                    update : false, // Update existing resource
                    id : null, // ID of resource to be updated
                    dataIdentifier : 'id', // Key to select to identify given data by passing it by prop
                },
                fieldStore : null
            }
        },
        provide() {
            let fieldStore = new FieldStore()
            this.fieldStore = fieldStore

            return { fieldStore }
        },
        props: {
            config: {
                type: Object,
                required: true,
                default: () => ({})
            },
            data : {
                type : Object, // Data
                default: () => ({})
            }
        },
        methods : {
            sendForm : function(){
                this.isLoading = true

                axios({
                    url : this.formEndpoint,
                    method : this.method,
                    data : this.fieldStore.serializeData()
                })
                    .then(response =>{
                        this.isLoading = false
                        this.$notifier.success(this.configuration.messageSuccessTitle, this.configuration.messageSuccess);

                        this.$emit('form-success', response);
                    })
                    .catch(error =>{
                        this.isLoading = false

                        if(error.response.status = 422) {
                            this.fieldStore.deserializeErrors(error.response.data.errors)
                            this.$notifier.error('Enkele gegevens zijn fout', 'Gelieve deze aan te passen');
                        }else{
                            this.$notifier.error('Er ging iets mis', 'Gelieve opnieuw te proberen');
                        }
                    })
            },
            setConfig : function(){
                // Get an id if needed
                let id = 0;
                if(this.configuration.update === true){
                    // use an id from the given data object or try to find an id prop
                    id = _.isEmpty(this.data) ? this.configuration.id : this.data[this.configuration.dataIdentifier]
                }

                // Update API endpoints
                this.dataEndpoint = this.configuration.dataUrl !== null ? this.configuration.dataUrl : this.configuration.url  + '/' + this.configuration.id

                if(this.configuration.update === false && this.configuration.storeUrl !== null) {
                    this.formEndpoint = this.configuration.storeUrl;
                    this.method = 'post'
                }else if(this.configuration.update === true && this.configuration.updateUrl !== null) {
                    this.formEndpoint = this.configuration.updateUrl;
                    this.method = 'put';
                }else if(this.configuration.update === false){
                    this.formEndpoint = this.configuration.url;
                    this.method = 'post'
                }else{
                    this.formEndpoint = this.configuration.url + '/' + id;
                    this.method = 'put';
                }

                // Get CSRF token
                this.csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
            },
            loadData : function(){
                // Load data if needed
                if(this.configuration.update === true){
                    if(_.isEmpty(this.data) === false){
                        // Use given data
                        deserializeData(this.data, this)
                    }else{
                        // Use an API endpoint to fetch data
                        axios.get(this.dataEndpoint)
                            .then(response =>{
                                let data = response.data.data

                                // Check we should transform the data from the API by an external function
                                if(this.$parent.transformData){
                                    data = this.$parent.transformData(data)
                                }

                                this.fieldStore.deserializeData(data)
                            })
                            .catch(error =>{
                                console.log(error);
                                this.$notifier.error()
                            })
                    }
                }
            },
            reset : function(){
                this.fieldStore.reset()
                this.setConfig()
                this.loadData()
            }

        },
        filters: {
            capitalize: function (value) {
                value = value.toString()
                return value.toUpperCase()
            }
        },
        computed: {
            configuration(){
                return {
                    ...this.defaultConfig,
                    ...this.config
                }
            }
        },
        watch: {
            '$route' (to, from) {
                this.reset();
            }
        },
        created() {
            this.setConfig();
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style>
    label.control-label{
        font-weight: 700;
    }
</style>
