<template>
  <div class="row" id="works" xmlns="">
    <div class="col-sm-3">
      <div class="panel">
        <div class="panel-heading panel-heading-color panel-heading-color-success">
          <div class="tools">
            <router-link :to="{ name: 'works.create'}"><span class="icon s7-plus"></span></router-link>
          </div>
          <span class="title">Bibliotheek</span>
        </div>
        <div class="panel-body">
          <search-filter></search-filter>
          <boolean-filter title="Favoriete Werken" filter="liked">
            <template slot="on">
              <i class="icon s7-like"></i> Toon Favoriete Werken
            </template>
            <template slot="off">
              <i class="icon s7-like"></i> Verberg Favoriete Werken
            </template>
          </boolean-filter>
          <select-filter title="Soort" filter="type" :options="typeOptions"></select-filter>
          <select-filter title="Leeftijd" filter="audience" :options="audienceOptions"></select-filter>
          <multi-select-filter title="Categorie" filter="work_categories" :options="categoryOptions"></multi-select-filter>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="panel panel-default panel-table panel-border-color-success">
        <div class="panel-body">
          <tabler api-url="/works" :initial-sort="initialSort" :fields="fields" :filters="filters" ref="table">
            <template slot="actions" slot-scope="props">
              <div class="table-actions">
                <router-link class="icon" :to="{ name: 'works.show', params: { workId: props.rowData.id }}">
                  <i class="s7-look"></i></router-link>
                <router-link v-if="$permissioner.can('update works', props.rowData.user_id)" class="icon" :to="{ name: 'works.edit', params: { workId: props.rowData.id  }}">
                  <i class="s7-pen"></i></router-link>
                <router-link v-if="$permissioner.can('delete works', props.rowData.user_id)" class="icon" :to="{ name: 'works.delete', params: { workId: props.rowData.id }}">
                  <i class="s7-trash"></i></router-link>
                <like-button class="action" like-class="liked" :work="props.rowData.id" v-model="props.rowData.liked"></like-button>
              </div>
            </template>
          </tabler>
        </div>
      </div>
      <span v-if="showDescriptionsInTable" style="font-size: 10px;float: right;margin-top:-20px;">Toon beschrijvingen: <a href="#" @click.prevent="hideDescriptions">Ja</a></span>
      <span v-else style="font-size: 10px;float: right;margin-top:-20px;">Toon beschrijvingen: <a href="#" @click.prevent="showDescriptions">Neen</a></span>
    </div>
  </div>
</template>

<script>
import LikeButton from './LikeButtonComponent.vue'
import Cookies from 'js-cookie';
import Vue from "vue";

export default {
  components: {
    LikeButton
  },
  data() {
    return {
      categoryOptions: {},
      typeOptions: {
        gedicht: 'Gedicht',
        kortverhaal: 'Kortverhaal',
        fragment: "Fragment van een verhaal",
        zeer_kort_verhaal: "Zeer kort verhaal"
      },
      audienceOptions: {
        volwassenen:"Volwassenen",
        volwassenen_grote_letters:"Volwassenen (grote letters)",
        volwassenen_duidelijke_taal:"Volwassenen (duidelijke taal)",
        kinderen_5:"Kinderen (5-7)",
        kinderen_7:"Kinderen (7-9)",
        kinderen_9:"Kinderen (9-12)",
        jongeren_12:"Jongeren (12-14)",
        jongeren_14:"Jongeren (14-16)",
      },
      fields: [
        {
          name: 'name',
          title: 'Naam',
          sortField: 'name'
        },
        {
          name: 'author',
          title: 'Auteur',
          sortField: 'author'
        },
        {
          name: 'description',
          title: 'Beschrijving',
          visible: false
        },
        {
          name: '__slot:actions',
          title: '',
          dataClass: 'actions right-aligned'
        },
      ],
      initialSort: [
        {
          field: 'author',
          direction: 'asc'
        }
      ],
      showDescriptionsInTable: false
    }
  },
  created() {
    axios.get('/works/categories')
        .then(response => {
          response.data.data.filter((value, key) => {
            Vue.set(this.categoryOptions, value.id, value.name);
          });
        })
        .catch(error => {
          this.$notifier.error()
        })

  },
  mounted() {
    // Check if we need to show descriptions
    if (Cookies.get('showWorksDescriptions') === 'true') {
      this.showDescriptions()
    }
  },
  computed: {
    filters: function () {
      if (this.$route.name === 'works.index') {
        return [{
          name: 'approved',
          parameters: [true]
        }];
      } else if (this.$route.name === 'works.unapproved') {
        return [{
          name: 'unapproved',
          parameters: [true]
        }];
      } else if (this.$route.name === 'works.own') {
        return [{
          name: 'own',
          parameters: [true]
        }];
      } else {
        this.$notifier.error('Er ging iets mis!', 'Onbekende route');
        return [];
      }
    }
  },
  methods: {
    showDescriptions() {
      this.showDescriptionsInTable = true
      Cookies.set('showWorksDescriptions', 'true', {expires: 365});
      this.$refs.table.showField(2)
    },
    hideDescriptions() {
      this.showDescriptionsInTable = false
      Cookies.set('showWorksDescriptions', 'false', {expires: 365});
      this.$refs.table.hideField(2)
    }
  }
}
</script>

<style>

</style>