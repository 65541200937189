<template>
    <former :config="config" @form-success="formSuccess">
        <div class="row">
            <div class="col-md-6">
                <field-string title="Naam" name="name"></field-string>
            </div>
            <div class="col-md-6">
                <field-string title="Url" name="url"></field-string>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <field-single-upload :maxSize="10" url="/upload/sponsor" storagePath="/storage/uploads/sponsors/" title="Afbeelding" name="image"></field-single-upload>
            </div>
        </div>
    </former>
</template>

<script>
    export default {
        data() {
            return {
                config: {},
            }
        },
        created() {
            if(this.$route.name === 'sponsors.create'){
                this.config = {
                    submitText: 'Toevoegen',
                    messageSuccessTitle: 'Sponsor Toegevoegd!',
                    url: '/sponsors',
                    update: false
                }
            }else if(this.$route.name === 'sponsors.edit'){
                this.config = {
                    id: this.sponsorId,
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Sponsor Gewijzigd!',
                    url: '/sponsors',
                    update: true
                }
            }else{
                this.$notifier.error('Ongekende route!')
            }
        },
        props : {
            sponsorId : {
                required : false,
                type : Number
            },
        },
        methods : {
            formSuccess: function (response) {
                this.$router.push({name: 'sponsors.show', params: {sponsorId: response.data.id}})
            }
        }
    }
</script>

<style>

</style>