<template>
    <div class="form-group">
        <label class="custom-control is-success custom-checkbox">
            <input type="checkbox"
                   v-bind:name="name"
                   v-model="value"
                   v-bind:class="{'is-invalid': hasError}"
                   class="custom-control-input"
            >
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{ title }}</span>
        </label>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin]
    }
</script>
