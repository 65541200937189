<template>
  <former :config="config" @form-success="formSuccess">
    <div class="row">
      <div class="col-md-6">
        <field-string title="Voornaam" name="firstname"></field-string>
      </div>
      <div class="col-md-6">
        <field-string title="Achternaam" name="lastname"></field-string>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <field-email title="Email" name="email"></field-email>
      </div>
      <div class="col-md-6">
        <field-string title="Telefoonnummer" name="phone"></field-string>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <field-string title="Straat + Nr." name="street"></field-string>
      </div>
      <div class="col-md-2">
        <field-string title="Postcode" name="zipcode"></field-string>
      </div>
      <div class="col-md-4">
        <field-string title="Stad" name="city"></field-string>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <field-number title="Credits" name="credits"></field-number>
      </div>
      <div class="col-md-4">
        <field-number title="Gebruikte Credits" name="used_credits"></field-number>
      </div>
      <div class="col-md-4">
        <field-select title="Rol" name="role.id" :options="roleOptions"></field-select>
      </div>
    </div>
    <div class="row" v-if="config.update === false">
      <div class="col-md-6">
        <field-password title="Wachtwoord" name="password"></field-password>
      </div>
      <div class="col-md-6">
        <field-password title="Wachtwoord (opnieuw)" name="password_confirmation"></field-password>
      </div>
    </div>
  </former>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      roleOptions: {},
      config: {}
    }
  },
  created() {
    if (this.$route.name === 'users.create') {
      this.config = {
        url: '/users',
        submitText: 'Toevoegen',
        messageSuccessTitle: 'Gebruiker Toegevoegd!',
        update: false
      }
    } else if (this.$route.name === 'users.edit') {
      this.config = {
        url: '/users',
        submitText: 'Wijzigen',
        messageSuccessTitle: 'Gebruiker Gewijzigd!',
        id: this.userId,
        update: true
      }
    } else {
      this.$notifier.error('Ongekende route!')
    }


    axios.get('/users/roles')
        .then(response => {
          response.data.data.filter((value, key) => {
            Vue.set(this.roleOptions, value.id, value.name);
          });
        })
        .catch(error => {
          this.$notifier.error()
        })
  },
  props: {
    userId: {}
  },
  methods: {
    formSuccess: function (response) {
      this.$router.push({name: 'users.show', params: {userId: response.data.id}})
    }
  }
}
</script>

<style>

</style>