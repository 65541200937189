<template>
    <div class="row page-head">
        <div class="col-sm-12 page-head-heading">
            <h1 style="text-align: center">Berichten met {{ conversation.correspondent.name }}</h1>
        </div>
        <div class="col-sm-12" style="text-align: center">
            <button class="btn btn-dark btn-xs" @click.prevent="toBottom">
                <span class="s7-angle-down"></span> Naar Benden <span class="s7-angle-down"></span>
            </button>
        </div>
        <div class="col-sm-12">
            <ul class="timeline">
                <span v-for="(messagesGroup, groupDate) in groupedMessages">
                    <li class="timeline-item" v-for="(message, index) in sortTimes(messagesGroup)">
                        <div class="timeline-date" v-if="index === 0"><span>{{ groupDate | formatDate }}</span></div>
                        <div class="timeline-content" >
                            <div class="timeline-header"><span class="timeline-time">{{ message.updated | formatTime }}</span><span class="timeline-autor">{{ message.writer }}</span>
                                <div class="timeline-summary" v-html="message.message"></div>
                            </div>
                        </div>
                    </li>
                </span>
                <message-feed-editor
                        :conversation="conversation.id"
                        :recipient="conversation.correspondent.id"
                        @message-send="newMessage"
                ></message-feed-editor>
            </ul>
        </div>
    </div>
</template>

<script>
    import MessageFeedEditor from './MessageFeedEditorComponent.vue'
    import moment from 'moment'

    export default {
        components : {
            MessageFeedEditor
        },
        props : {
            conversationId : {
                required : true
            }
        },
        data() {
            return {
                conversation : {
                    id : 0,
                    correspondent : {
                        id : 0,
                        name : '',
                    },
                    messages : []
                }
            }
        },
        filters : {
            formatTime: function (value) {
                let fmt = 'HH:mm'
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            },
            formatDate: function (value) {
                let fmt = 'D MMM YYYY'
                return (value === null)
                    ? ''
                    : moment.unix(value).locale('nl').format(fmt)
            }
        },
        computed : {
            groupedMessages : function(){
                let reduced = this.conversation.messages.reduce(function (conversations, conversation) {
                    let period = moment.unix(conversation.updated).seconds(0).minutes(0).hours(0).format('X')

                    conversations[period] = conversations[period] || [];
                    conversations[period].push(conversation);
                    return conversations;
                }, {})

                return reduced
            }
        },
        methods : {
            loadConversation(){
                axios.get('/conversations/' + this.conversationId)
                    .then(response => {
                        this.conversation = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })

                this.$parent.newConversations = this.$parent.newConversations.filter(conversation => {
                    return conversation.id !== this.conversationId
                })
            },
            newMessage(content){
                this.conversation.messages.push({
                    id : 0,
                    message : content,
                    updated: moment().unix(),
                    writer : this.$permissioner.user.firstname + ' ' + this.$permissioner.user.lastname
                })
            },
            sortTimes(messages){
                return messages.sort(function(a, b){
                    return a.updated - b.updated;
                })
            },
            toBottom(){
                window.scrollTo(0,document.body.scrollHeight);
            }
        },
        watch : {
            '$route' : 'loadConversation'
        },
        created() {
            this.loadConversation()
        }
    }
</script>

<style>

</style>