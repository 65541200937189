<template>
  <former :config="config" @form-success="formSuccess">
    <div class="row">
      <div class="col-md-6">
        <field-string title="Naam" name="name"></field-string>
      </div>
      <div class="col-md-6">
        <field-string title="Auteur" name="author"></field-string>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <field-select title="Soort" name="type" :options="typeOptions"></field-select>
      </div>
      <div class="col-md-6">
        <field-select title="Leeftijd" name="audience" :options="audienceOptions"></field-select>
      </div>
    </div>
    <field-select2 title="Categorieën" name="categories" multiple searchable :max="3" :options="categoryOptions"></field-select2>
    <field-wysiwyg title="Beschrijving" :toolbar="toolbar" name="description"></field-wysiwyg>
    <set-show title="Formaat" description="Hoe wilt u het werk online zetten?" name="format" @form-success="formSuccess">
      <set-item title="Bestand Uploaden" name="file">
        <field-single-upload :maxSize="10" url="/upload/work" storagePath="/storage/uploads/works/" title="Bestand" name="file"></field-single-upload>
      </set-item>
      <set-item title="Tekst Invoegen" name="text">
        <field-wysiwyg title="Tekst" name="content"></field-wysiwyg>
      </set-item>
    </set-show>
  </former>
</template>

<script>
export default {
  data() {
    return {
      categoryOptions: [],
      typeOptions: {
        gedicht: 'Gedicht',
        kortverhaal: 'Kortverhaal',
        fragment: "Fragment van een verhaal",
        zeer_kort_verhaal: "Zeer kort verhaal"
      },
      audienceOptions: {
        volwassenen:"Volwassenen",
        volwassenen_grote_letters:"Volwassenen (grote letters)",
        volwassenen_duidelijke_taal:"Volwassenen (duidelijke taal)",
        kinderen_5:"Kinderen (5-7)",
        kinderen_7:"Kinderen (7-9)",
        kinderen_9:"Kinderen (9-12)",
        jongeren_12:"Jongeren (12-14)",
        jongeren_14:"Jongeren (14-16)",
      },
      formatOptions: {
        file: 'Bestand Uploaden',
        text: 'Tekst'
      },
      config: {},
      toolbar: ["bold", "italic", "underline"]
    }
  },
  created() {
    if (this.$route.name === 'works.create') {
      this.config = {
        url: '/works',
        submitText: 'Toevoegen',
        messageSuccessTitle: 'Werk Toegevoegd!',
        messageSuccess: 'Het werk wordt pas publiek als het goed gekeurd wordt!',
        update: false
      }
    } else if (this.$route.name === 'works.edit') {
      this.config = {
        url: '/works',
        submitText: 'Wijzigen',
        messageSuccessTitle: 'Werk Gewijzigd!',
        id: this.workId,
        update: true
      }
    } else {
      this.$notifier.error('Ongekende route!')
    }


    axios.get('/works/categories')
        .then(response => {
          response.data.data.forEach((category) => {
            this.categoryOptions.push({
              id: category.id,
              name: category.name
            })
          });
        })
        .catch(error => {
          this.$notifier.error()
        })
  },
  props: {
    workId: {}
  },
  methods: {
    transformData: function (data) {
      let categories = []
      for (let category of data.categories) {
        categories.push(category.id)
      }

      data.categories = categories
      return data
    },
    formSuccess: function (response) {
      if (this.config.update) {
        this.$router.push({name: 'works.show', params: {workId: response.data.id}})
      } else {
        this.$router.push({name: 'works.index'})
      }
    }
  }

}
</script>

<style>

</style>