class Field {
    constructor(name, identifier = null) {
        this.name = name;

        //let segments = name.split('.')
        //this.key = segments.pop()
        //this.scope = segments
        this.error = ''
        this.data = null
        this.identifier = identifier
    }

    hasError(){
        return this.error !== ''
    }

    set value(data){
        this.data = data
        this.error = ''
    }

    get value(){
        return this.data
    }

    isField(name, identifier = null){
        return this.name === name && this.identifier === identifier
    }

};

export default Field