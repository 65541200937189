<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <input type="email"
               class="form-control"
               v-model="value"
               v-bind:placeholder="title"
               v-bind:name="name"
               v-bind:class="{'is-invalid': hasError}"
        />
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin]
    }
</script>
