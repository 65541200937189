<template>
    <li class="nav-item">
        <a href="#" class="nav-link" :class="{active}" @click.prevent="setActive">
            <span :class="iconClass"></span>
            <span class="name">{{ name }}</span>
        </a>
    </li>
</template>

<script>
    export default {
        data() {
            return {
                active : false
            }
        },
        props : {
            name : {
                type: String,
                required : true,
            },
            icon : {
                type: String,
                required : true,
            },
            route : {
                type: String,
                required : true,
            },
            directRoute :{
                type: Boolean,
                required : false,
                default : false
            }
        },
        computed : {
            iconClass : function(){
                return 'icon s7-' + this.icon
            },
            routeName: function(){
                return this.directRoute === false ?
                    this.$parent.route + '.' + this.route :
                    this.route
            }
        },
        methods : {
            setActive : function(){
                if(this.directRoute === true){
                    window.location = this.routeName
                }else{
                    this.$router.push({name : this.routeName})
                }

                this.$parent.$children.forEach(function(menuItem){
                    menuItem.active = false
                })

                this.active = true
            }
        }
    }
</script>

<style>

</style>