<template>
    <basic-layout>
        <template slot="side">
            <span class="alternative-message text-right">
                <router-link :to="{name: 'authentication.login'}">Inloggen?</router-link>
            </span>
        </template>
        <template slot="title">
            Wachtwoord Vergeten
        </template>
        <template slot="content" v-if="success === false">
            <div>
                <form method="POST" action="">
                    <div v-if="errors !== ''" role="alert" class="alert alert-contrast alert-danger alert-dismissible">
                        <div class="message">
                            <strong>Er ging iets fout!</strong><br />
                            <span v-html="errors"></span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="input-group"><span class="input-group-addon"><i class="icon s7-mail"></i></span>
                            <input id="email" type="email" v-model="email" placeholder="Email Adres"  class="form-control">
                        </div>
                    </div>

                    <div class="form-group login-submit login-tools">
                        <input @click.prevent="formSubmit" type="submit" class="btn btn-lg btn-primary btn-block" value="Stuur een reset link" />
                    </div>
                </form>
            </div>
        </template>
        <template slot="content" v-else>
            <div role="alert" class="alert alert-contrast alert-success alert-dismissible">
                <div class="message">
                    <strong>Gelukt!</strong><br />
                    <span>U heeft een mail ontvangen met daarin instructies om uw wachtwoord opnieuw in te stellen.</span>
                </div>
            </div>
        </template>
    </basic-layout>
</template>

<script>
    import BasicLayoutComponent from '../layouts/BasicLayoutComponent.vue'

    export default {
        components : {
            'basic-layout': BasicLayoutComponent
        },
        data(){
            return {
                email : '',
                errors : '',
                success : false,
            }
        },
        watch : {
            '$route' : function(newRoute, oldRoute){
                this.email = ''
                this.errors = ''
                this.success = false
            }
        },
        methods : {
            formSubmit : function(){
                axios.post('/authentication/forgotPassword', {
                    email : this.email,
                }).then(response => {
                    this.success = true
                }).catch(error => {
                    if(error.response.status === 422){
                        this.errors = 'Enkele velden zijn fout:<br />'

                        Object.entries(error.response.data.errors)
                            .forEach(([key, value]) => {
                                this.errors += '- <b>' + key + ':</b> ' + value.join() + '<br />'
                            })
                    }else{
                        this.errors = 'Gelieve opnieuw te proberen!'
                    }
                })
            }
        }
    }
</script>

<style>

</style>