<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-divider panel-heading-full-width">
      <span class="title">Leesgroep {{ config.submitText }}</span></div>
    <div class="panel-body">
      <former :config="config" ref="form" @form-success="formSuccess">
        <div class="row">
          <div class="col-md-6">
            <field-string title="Naam" name="name"></field-string>
          </div>
          <div class="col-md-6">
            <field-select title="Categorie" name="category.id" :options="categoryOptions"></field-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <field-string title="Straat + Nr." name="street"></field-string>
          </div>
          <div class="col-md-2">
            <field-string title="Postcode" name="zipcode"></field-string>
          </div>
          <div class="col-md-4">
            <field-string title="Stad" name="city"></field-string>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Verantwoordelijke</h5>
          </div>
          <div class="col-md-4">
            <field-string title="Naam" name="responsible_name"></field-string>
          </div>
          <div class="col-md-4">
            <field-string title="Email Addres" name="responsible_mail"></field-string>
          </div>
          <div class="col-md-4">
            <field-string title="Telefoonnummer" name="responsible_phone"></field-string>
          </div>
        </div>
        <div class="row" v-if="config.update === false">
          <div class="col-md-12">
            <set-show title="Periode" description="Hoelang loopt deze groep? (Je kunt later meerdere periodes in een groep toevoegen)" name="periodType">
              <set-item title="Deze groep loopt voor een beperkte periode" name="temporary">
                <div class="row">
                  <div class="col-md-6">
                    <field-date title="Startdatum" name="start"></field-date>
                  </div>
                  <div class="col-md-6">
                    <field-date title="Einddatum" name="end"></field-date>
                  </div>
                </div>
              </set-item>
              <set-item title="Deze groep heeft nog geen einddatum" name="infinity">
                <div class="row">
                  <div class="col-md-6">
                    <field-date title="Startdatum" name="start"></field-date>
                  </div>
                </div>
              </set-item>
            </set-show>
          </div>
        </div>
      </former>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      config: {},
      categoryOptions: {}
    }
  },
  props: {
    groupId: {
      required: false
    }
  },
  methods: {
    formSuccess: function (response) {
      this.$router.push({name: 'groups.show', params: {groupId: response.data.id}})
    }
  },
  created() {
    if (this.$route.name === 'groups.create') {
      this.config = {
        submitText: 'Toevoegen',
        messageSuccessTitle: 'Leesgroep Toegevoegd!',
        storeUrl: '/groups/',
        update: false
      }
    } else if (this.$route.name === 'groups.edit') {
      this.config = {
        submitText: 'Wijzigen',
        messageSuccessTitle: 'Leesgroep Gewijzigd!',
        dataUrl: '/groups/' + this.groupId,
        updateUrl: '/groups/' + this.groupId,
        update: true
      }
    } else {
      this.$notifier.error('Ongekende route!')
    }

    axios.get('/groups/categories')
        .then(response => {
          response.data.data.filter((value, key) => {
            Vue.set(this.categoryOptions, value.id, value.name);
          });
        })
        .catch(error => {
          this.$notifier.error()
        })
  }
}
</script>

<style>

</style>