<template>
    <div class="row" style="margin-top: 20px;">
        <div class="col-md-12">
            <div class="panel panel-default">
                <div class="panel-heading panel-heading-divider">
                    <span class="title">Leesgroep overzicht</span>
                    <span class="panel-subtitle">Ontdek de progressie van uw leesgroep.</span>
                </div>
                <div class="panel-body">
                    <show-at breakpoint="mediumAndAbove">
                        <chart-experiences :height="100" :data="data.experiences"></chart-experiences>
                    </show-at>
                    <show-at breakpoint="small">
                        <chart-experiences :data="data.experiences"></chart-experiences>
                    </show-at>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading panel-heading-divider">
                    <span class="title">Geslacht Participanten</span>
                </div>
                <div class="panel-body">
                    <chart-gender :height="150" :data="data.genders"></chart-gender>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading panel-heading-divider">
                    <span class="title">Leeftijd Participanten</span>
                </div>
                <div class="panel-body">
                    <show-at breakpoint="mediumAndAbove">
                        <chart-age :height="150" :data="data.ages"></chart-age>
                    </show-at>
                    <show-at breakpoint="small">
                        <chart-age :data="data.ages"></chart-age>
                    </show-at>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ExperiencesChart from './charts/ExperiencesChartComponent.vue'
    import GenderChart from './charts/GenderChartComponent.vue'
    import AgeChart from './charts/AgeChartComponent.vue'
    import {showAt} from 'vue-breakpoints'

    export default {
        components : {
            'chart-experiences' : ExperiencesChart,
            'chart-gender' : GenderChart,
            'chart-age' : AgeChart,
            showAt
        },
        props : {
            groupId : {
                required: true,
            },
            periodId : {
                required: false,
            }
        },
        watch: {
            periodId : function(newPeriod, oldPeriod){
                this.loadData();
            }
        },
        data() {
            return {
                data : {
                    experiences : [],
                    genders : {},
                    ages : [],
                }
            }
        },
        methods : {
            loadData : function(){
                axios.get('/groups/' + this.groupId + '/periods/' + this.periodId + '/charts')
                    .then(response => {
                        this.data = response.data;
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            }
        },
        created(){
            this.loadData();
        }
    }
</script>

<style>

</style>