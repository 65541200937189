<template>
  <div class="row">
    <div class="col-sm-3">
      <div class="panel">
        <div class="panel-heading panel-heading-color panel-heading-color-success">
          <div class="tools">
            <router-link class="icon" :to="{ name: 'groups.create'}">
              <span class="s7-plus"></span>
            </router-link>
          </div>
          <span class="title">Leesgroepen</span>
        </div>
        <div class="panel-body">
          <search-filter></search-filter>
          <select-filter title="Soort" filter="group_categories" :options="categoryOptions"></select-filter>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="panel panel-default panel-table panel-border-color-success">
        <div class="panel-body">
          <tabler :api-url="apiUrl" url="/users" :fields="fields" :history="false" :includes="['owner', 'category', 'periods']">
            <template slot="actions" slot-scope="props">
              <div class="table-actions">
                <router-link class="icon" :to="{ name: 'groups.show', params: { groupId: props.rowData.id }}">
                  <i class="s7-look"></i></router-link>
                <router-link class="icon" :to="{ name: 'groups.edit', params: { groupId: props.rowData.id }}">
                  <i class="s7-pen"></i></router-link>
                <router-link class="icon" :to="{ name: 'groups.delete', params: { groupId: props.rowData.id }}">
                  <i class="s7-trash"></i></router-link>
              </div>
            </template>
          </tabler>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      categoryOptions: {},
      fields: [
        {
          name: 'name',
          title: 'Naam',
          sortField: 'name',
        },
        {
          name: 'owner',
          title: 'Begeleider',
          sortField: 'user_id',
          callback: function (value, fmt) {
            if (_.isEmpty(value) === false) {
              return value.firstname + ' ' + value.lastname;
            }

          }
        },
        {
          name: 'category',
          title: 'Categorie',
          sortField: 'reading_group_category_id',
          callback: function (value, fmt) {
            if (_.isEmpty(value) === false) {
              return value.name;
            }

          }
        },
        {
          name: 'is_active',
          title: 'Actief',
          callback: function (value, fmt) {
            if (value === true) {
              return '<span class="badge badge-success">Ja</span>'
            } else {
              return '<span class="badge badge-danger">Afgelopen</span>'
            }
          }
        },
        {
          name: '__slot:actions',
          title: '',
          dataClass: 'actions float-right'
        }
      ],
    }
  },
  computed: {
    apiUrl: function () {
      return '/groups/';
    }
  },
  methods: {
    onAction(action, data, index) {
      console.log('slot action: ' + action, data.id, index)
    },
  },
  created() {
    axios.get('/groups/categories')
        .then(response => {
          response.data.data.filter((value, key) => {
            Vue.set(this.categoryOptions, value.id, value.name);
          });
        })
        .catch(error => {
          this.$notifier.error()
        })
  }
}
</script>

<style>

</style>