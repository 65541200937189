<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <div>
            <button v-for="icon in icons"
                    @click.prevent="setIcon(icon)"
                    :class="{'btn-info' : icon === value}"
                    class="iconBtn btn btn-space btn-secondary">
                <i class="icon icon-left" :class="icon | iconFilter"></i>
            </button>
        </div>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin],
        data() {
            return {
                icons : ['diamond', 'door-lock', 'eyedropper', 'hammer', 'leaf', 'map-2', 'pendrive', 'photo', 'piggy', 'plugin', 'rocket', 'refresh-2', 'settings', 'shield', 'cash', 'way', 'id', 'world', 'users', 'wallet', 'video', 'umbrella', 'trash', 'upload', 'tools', 'target', 'study', 'star', 'shopbag', 'server', 'search', 'film', 'science', 'note2', 'print', 'portfolio', 'medal', 'plane', 'key', 'joy', 'phone', 'pen', 'notebook', 'news-paper', 'monitor', 'map', 'map-marker', 'mail', 'like', 'info', 'home', 'help2', 'help1', 'global', 'gift', 'flag', 'drawer', 'date', 'culture', 'config', 'compass', 'cloud', 'comment', 'cart', 'chat', 'box1', 'bookmarks', 'albums']
            }
        },
        filters : {
            'iconFilter' : function(icon){
                return 's7-' + icon
            }
        },
        methods : {
            'setIcon' : function(icon){
                this.value = icon
            }
        }
    }
</script>

<style>
    .iconBtn{
        width: 38px;
    }
</style>
