<template>
    <former :config="config">
        <div class="row">
            <div class="col-md-12">
                <h2>Meld een probleem.</h2>
            </div>
            <div class="col-md-12">
                <field-string title="Titel" name="title"></field-string>
            </div>
            <div class="col-md-12">
                <field-text title="Probleem" name="content"></field-text>
            </div>
        </div>
    </former>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    submitText: 'Versturen',
                    messageSuccessTitle: 'Bericht verstuurd, we antwoorden zo snel mogelijk!',
                    storeUrl: '/helps/support',
                    update: false,
                },
            }
        },
    }
</script>

<style>

</style>