<template>
    <div class="row">
        <div class="col-sm-3">
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-success">
                    <div class="tools">
                        <router-link class="icon" :to="{ name: 'sponsors.create'}">
                            <span class="s7-plus"></span>
                        </router-link>
                    </div>
                    <span class="title">Sponsors</span>
                </div>
                <div class="panel-body">
                    <p>Sponsors kunnen hier beheerd worden</p>
                </div>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="panel panel-default panel-table panel-border-color-success">
                <div class="panel-body">
                    <tabler api-url="/sponsors" url="/sponsors" :fields="fields" :history="false">
                        <template slot="actions" slot-scope="props">
                            <div class="table-actions">
                                <router-link class="icon" :to="{ name: 'sponsors.show', params: { sponsorId: props.rowData.id }}"><i class="s7-look"></i></router-link>
                                <router-link class="icon" :to="{ name: 'sponsors.edit', params: { sponsorId: props.rowData.id }}"><i class="s7-pen"></i></router-link>
                                <router-link class="icon" :to="{ name: 'sponsors.delete', params: { sponsorId: props.rowData.id }}"><i class="s7-trash"></i></router-link>
                            </div>
                        </template>
                    </tabler>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fields: [
                    {
                        name : 'name',
                        title : 'Naam',
                        sortField: 'name',
                    },
                    {
                        name: '__slot:actions',
                        title: '',
                        dataClass: 'actions float-right'
                    }
                ],
            }
        },
    }
</script>

<style>

</style>