<template>
    <basic-layout>
        <template slot="side">
            <span class="alternative-message text-right">
                Geen account? <router-link :to="{name: 'authentication.register'}">Registreer</router-link>
            </span>
        </template>
        <template slot="title">
            Inloggen
        </template>
        <template slot="content">
            <div>
                <form method="POST" action="">
                    <div v-if="errors !== ''" role="alert" class="alert alert-contrast alert-danger alert-dismissible">
                        <div class="message">
                            <strong>Er ging iets fout!</strong><br />
                            <span v-html="errors"></span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                    <span class="input-group-addon">
                        <i class="icon s7-mail"></i>
                    </span>
                            <input id="email" type="email" v-model="email" placeholder="Email Adres"  class="form-control">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                    <span class="input-group-addon">
                        <i class="icon s7-lock"></i>
                    </span>
                            <input id="password" type="password" v-model="password" placeholder="Wachtwoord" class="form-control">
                        </div>
                    </div>
                    <div class="form-group login-submit">
                        <input @click.prevent="formSubmit" type="submit" class="btn btn-lg btn-primary btn-block" value="Aanmelden" />
                    </div>
                    <div class="form-group row login-tools">
                        <div class="col-sm-6 login-forgot-password">
                            <router-link :to="{name: 'authentication.forgot'}">Wachtwoord vergeten?</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </basic-layout>
</template>

<script>
    import BasicLayoutComponent from '../layouts/BasicLayoutComponent.vue'
    import Cookies from 'js-cookie';

    export default {
        components : {
            'basic-layout': BasicLayoutComponent
        },
        data(){
            return {
                email : '',
                password : '',
                errors : '',
            }
        },
        methods : {
            formSubmit : function(){
                axios.post('/authentication/login', {
                    email : this.email,
                    password : this.password
                }).then(response => {
                    this.$parent.setAccessToken(response.data.access_token, response.data.expires_in)

                    // Set the permissioner container
                    this.$permissioner.user = response.data.user
                    window.permissioner = this.$permissioner

                    if(this.$permissioner.role.name === 'Ongeactiveerd'){
                        this.$router.push({name : 'users.form'})
                    }else{
                        this.$router.push({name : 'groups.index'})
                    }
                }).catch(error => {
                    console.log(error)
                    if(error.response.status === 422){
                        this.errors = 'Enkele velden zijn fout:<br />'

                        Object.entries(error.response.data.errors)
                            .forEach(([key, value]) => {
                                this.errors += '- <b>' + key + ':</b> ' + value.join() + '<br />'
                            })
                    }else if(error.response.status === 401){
                        this.errors = 'Uw email adres of wachtwoord is fout!'
                    }else{
                        this.errors = 'Gelieve opnieuw te proberen!'
                    }
                })
            }
        }
    }
</script>

<style>

</style>