<template>
    <basic-layout>
        <template slot="side">
            <span class="alternative-message text-right">
                <router-link :to="{name: 'authentication.login'}">Inloggen?</router-link>
            </span>
        </template>
        <template slot="title">
            Wachtwoord Opniew Instellen
        </template>
        <template slot="content">
            <div>
                <form method="POST" action="">
                    <div v-if="errors !== ''" role="alert" class="alert alert-contrast alert-danger alert-dismissible">
                        <div class="message">
                            <strong>Er ging iets fout!</strong><br />
                            <span v-html="errors"></span>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="input-group"><span class="input-group-addon"><i class="icon s7-mail"></i></span>
                            <input id="email" v-model="data.email" name="email" placeholder="Email Adres" class="form-control">
                        </div>
                    </div>

                    <div class="form-group inline row">
                        <div class="col-sm-6 nopad">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-lock"></i></span>
                                <input type="password" v-model="data.password" placeholder="Wachtwoord" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6 nopad">
                            <div class="input-group"><span class="input-group-addon"><i class="icon s7-lock"></i></span>
                                <input type="password" v-model="data.password_confirmation" placeholder="Opnieuw" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="form-group login-submit">
                        <input @click.prevent="formSubmit" type="submit" class="btn btn-lg btn-primary btn-block" value="Stel Wachtwoord Opnieuw In" />
                    </div>
                </form>
            </div>
        </template>
    </basic-layout>
</template>

<script>
    import BasicLayoutComponent from '../layouts/BasicLayoutComponent.vue'

    export default {
        components : {
            'basic-layout': BasicLayoutComponent
        },
        data(){
            return {
                data : {
                    email : '',
                    password : '',
                    password_confirmation: '',
                    token : '',
                },
                errors : '',
            }
        },
        created(){
            if(this.token === undefined){
                this.errors = 'Onbekend token, gelieve opnieuw de link in uw mail te openen'
            }

            this.data.token = this.token
            this.data.email = this.email
        },
        props : {
            token : {
                required : true,
                type: String,
            },
            email : {
                required : false,
            }
        },
        methods : {
            formSubmit : function(){
                axios.post('/authentication/resetPassword', this.data).then(response => {
                    this.$router.push({name : 'authentication.login'})
                    this.$notifier.success('Wachtwoord opnieuw ingesteld!', 'Je kan nu opnieuw inloggen')
                }).catch(error => {
                    if(error.response.status === 422){
                        this.errors = 'Enkele velden zijn fout:<br />'

                        Object.entries(error.response.data.errors)
                            .forEach(([key, value]) => {
                                this.errors += '- <b>' + key + ':</b> ' + value.join() + '<br />'
                            })
                    }else{
                        this.errors = 'Gelieve opnieuw te proberen!'
                    }
                })
            }
        }
    }
</script>

<style>
    .nopad{
        padding-left: 0px;
        padding-right: 0px;
    }
</style>