<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-heading-divider panel-heading-full-width"><span class="title">Dag {{ config.submitText }}</span></div>
        <div class="panel-body">
            <former :config="config" ref="form" @form-success="formSuccess">
                <div class="row">
                    <div class="col-md-12">
                        <field-datetime title="Dag" name="date"></field-datetime>
                    </div>
                </div>
            </former>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {

                },
            }
        },
        created() {
            if(this.$route.name === 'days.create'){
                this.config = {
                    submitText: 'Toevoegen',
                    messageSuccessTitle: 'Dag Toegevoegd!',
                    storeUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/days',
                    update: false
                }
            }else if(this.$route.name === 'days.edit'){
                this.config = {
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Dag Gewijzigd!',
                    dataUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/days/' + this.dayId,
                    updateUrl: '/groups/' + this.groupId + '/periods/' + this.periodId + '/days/' + this.dayId,
                    update: true
                }
            }else{
                this.$notifier.error('Ongekende route!')
            }
        },
        methods : {
            transformData : function(data){
                data.date *= 1000
                return data
            },
            formSuccess : function(response){
                this.$router.push({ name: 'days', params: { groupId: this.groupId, periodId: this.periodId }})
            }
        },
        props : {
            groupId : {
                required : true
            },
            periodId : {
                required : true,
            },
            dayId : {
                required : false,
            },
        }
    }
</script>

<style>

</style>