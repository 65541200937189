<template>
    <div v-show="isActive">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props : {
            name : {
                type: String,
                required: true
            },
            title : {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                //isActive : false
            }
        },
        computed : {
            isActive : function () {
                return this.$parent.value === this.name;
            }
        }
    }
</script>

<style>

</style>