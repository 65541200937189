<template>
    <div>
        <span class="table-filter-title">{{ title }} <a href="#" v-show="showAll" @click.prevent="deselect">alle</a></span>
        <div class="filter-container">
            <div v-if="Object.keys(options).length <= 3" class="custom-controls-stacked" style="margin-top: 10px;">
                <label class="custom-control custom-checkbox" v-for="(option, name) in options">
                    <input @change="sendFilter" type="checkbox" v-model="selected" :name="filter" :value="name" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{ option }}</span>
                </label>
            </div>
            <div v-else >
                <show-at breakpoint="mediumAndAbove">
                    <div class="custom-controls-stacked" style="margin-top: 10px;">
                        <label class="custom-control custom-checkbox" v-for="(option, name) in options">
                            <input @change="sendFilter" type="checkbox" v-model="selected" :name="filter" :value="name" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{ option }}</span>
                        </label>
                    </div>
                </show-at>
                <show-at breakpoint="small">
                    <div v-if="showItemsOnMobile === false" >
                            <a href="#" @click.prevent="toggleItemsOnMobile"><span class="s7-angle-down"></span> Toon Items</a>
                    </div>
                    <div v-else class="custom-controls-stacked" style="margin-top: 10px;">
                        <label class="custom-control custom-checkbox" v-for="(option, name) in options">
                            <input @change="sendFilter" type="checkbox" v-model="selected" :name="filter" :value="name" class="custom-control-input">
                            <span class="custom-control-indicator"></span>
                            <span class="custom-control-description">{{ option }}</span>
                        </label>
                        <a href="#" @click.prevent="toggleItemsOnMobile"><span class="s7-angle-up"></span> Verberg Items</a>
                    </div>
                </show-at>
            </div>
        </div>
    </div>
</template>

<script>
    import {showAt} from 'vue-breakpoints'

    export default {
        components : {
            showAt
        },
        props : {
            title: {
                type : String,
                required : true
            },
            filter: {
                type : String,
                required : true
            },
            options: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            url : {
                type: String,
                default : ''
            }
        },
        data () {
            return {
                selected: [],
                showItemsOnMobile : false,
            }
        },
        computed : {
            showAll : function () {
                return !(this.selected === undefined || this.selected.length === 0)
            }
        },
        methods: {
            sendFilter () {
                this.$events.fire('filter', {
                    filter : this.filter,
                    selected : this.selected
                });
            },
            deselect() {
                this.selected = []
                this.sendFilter()
            },
            toggleItemsOnMobile() {
                this.showItemsOnMobile = !this.showItemsOnMobile
            }
        },
        mounted (){
            this.$events.$on('set-filter' , eventData => {
                if(this.filter in eventData){
                    this.selected = eventData[this.filter]
                }
            })
        },
    }
</script>

<style>
    .table-filter-title a{
        font-size: 10px;
    }
</style>