import '../../maisonnette/lib/jquery.gritter/js/jquery.gritter'

/**
 * Time in miliseconds
 *
 * @type {{message: (function(*=, *=, *=))}}
 */
const Noty = {
    message(title = '', text = '', time = 5000) {
        $.gritter.add({
            title: title,
            text: text,
            time: time,
            class_name: 'gritter-color info'
        });
    },

    success(title = '', text = '', time = 5000) {
        $.gritter.add({
            title: title,
            text: text,
            time: time,
            class_name: 'gritter-color success'
        });
    },

    error(title = '', text = '', time = 5000) {
        console.trace();

        if(title === '' && text === ''){
            $.gritter.add({
                title: 'Er ging iets mis!',
                text: 'Gelieve opnieuw te proberen.',
                time: time,
                class_name: 'gritter-color danger'
            });
        }else{
            $.gritter.add({
                title: title,
                text: text,
                time: time,
                class_name: 'gritter-color danger'
            });
        }
    },

    warning(title = '', text = '', time = 5000) {
        $.gritter.add({
            title: title,
            text: text,
            time: time,
            class_name: 'gritter-color warning'
        });
    },

    dark(title = '', text = '', time = 5000) {
        $.gritter.add({
            title: title,
            text: text,
            time: time,
            class_name: 'gritter-color dark'
        });
    },
}

export default Noty;