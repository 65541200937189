<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="panel panel-default panel-table">
                <div class="filters">
                    <div class="filters-title">
                        Participanten
                    </div>
                    <div class="filters-content d-flex flex-row justify-content-between d-flex align-items-center">
                        <div class="p-2">
                            <search-filter></search-filter>
                        </div>
                        <div class="p-2">
                            <router-link class="btn btn-rounded btn-space btn-success" :to="{ name: 'participants.create', params: { periodId: periodId }}">Toevoegen</router-link>
                        </div>
                    </div>
                </div>
                <hr style="margin-top: 3px;margin-bottom: 3px;" />
                <div class="panel-body">
                    <tabler :api-url="apiUrl" :initial-sort="initialSort" :fields="fields" :history="false">
                        <template slot="actions" slot-scope="props">
                            <div class="table-actions">
                                <router-link class="icon" :to="{ name: 'participants.show', params: { periodId: periodId, participantId: props.rowData.id }}"><i class="s7-look"></i></router-link>
                                <router-link class="icon" :to="{ name: 'participants.edit', params: { periodId: periodId, participantId: props.rowData.id }}"><i class="s7-pen"></i></router-link>
                                <router-link class="icon" :to="{ name: 'participants.delete', params: { periodId: periodId, participantId: props.rowData.id }}"><i class="s7-trash"></i></router-link>
                            </div>
                        </template>
                    </tabler>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                fields: [
                    {
                        name : 'name',
                        title : 'Voornaam',
                        sortField: 'name'
                    },
                    {
                        name : 'gender',
                        title : 'Geslacht',
                        sortField: 'gender',
                    },
                    {
                        name : 'age',
                        title : 'Leeftijd',
                        sortField : 'age'
                    },
                    {
                        name : 'language',
                        title : 'Taal',
                        sortField : 'language'
                    },
                    {
                        name : 'employment_status',
                        title : 'Arbeidsstatus',
                        sortField : 'employment_status'
                    },
                    {
                        name: '__slot:actions',
                        title: '',
                        dataClass: 'actions float-right'
                    }
                ],
                initialSort : [
                    {
                        field: 'name',
                        direction: 'asc'
                    }
                ]
            }
        },
        props : {
            groupId : {
                required: true,
            },
            periodId : {
                required: true,
            }
        },
        computed : {
            apiUrl :  function(){
                return '/groups/' + this.groupId + '/periods/' + this.periodId + '/participants';
            }
        }
    }
</script>

<style>

</style>