<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <multiselect
                v-model="value"
                :options="options"
                :multiple="multiple"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                :max="max"
                :searchable="searchable"
                :placeholder="title"
                label="name"
                track-by="id"
                selectLabel="Druk enter om te selecteren"
                selectedLabel="Geselecteerd"
                deselectLabel="Druk enter om te verwijderen">
            <template slot="maxElements">
                Je hebt het maximale aantal opties geselcteerd
            </template>
            <template slot="noResult">
                Geen resultaten gevonden
            </template>

        </multiselect>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {OptionsFieldMixin} from '../../mixins/OptionsFieldMixin.js'
    import Multiselect from 'vue-multiselect'
    import _ from 'lodash'

    export default {
        components : {
            Multiselect
        },
        mixins : [OptionsFieldMixin],
        props : {
            multiple : {
                type : Boolean,
                default : false
            },
            searchable : {
                type : Boolean,
                default : false
            },
            max : {
                type : Number,
                default : null
            }
        },
        computed: {
            value: {
                get() {
                    let value = this.fieldStore.value(this.name, this.id)
                    if (_.isArray(value)) {
                        let output = value.map((valueId) => {
                            let valueName = this.options.find((category) => {
                                return category.id === valueId
                            })

                            if(valueName === undefined){
                                return {id: valueId, name: ''}
                            }else{
                                return {id: valueId, name: valueName.name}
                            }

                        })
                        return output
                    }else{
                        return value
                    }
                },
                set(values) {
                    let output = values.map((value) => {
                        return value.id
                    })

                    this.fieldStore.setValue(output, this.name, this.id)
                }
            },
        }

    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<styles>

</styles>