<template>
    <destroy-view type="Werk" backRoute="works.index" :apiUrl="apiUrl"></destroy-view>
</template>

<script>
    import DestroyComponent from '../layouts/DestoryComponent.vue'

    export default {
        components : {
            'destroy-view' : DestroyComponent
        },
        props : {
            workId : {
                required : true
            },
        },
        computed : {
            apiUrl : function () {
                return '/works/' + this.workId
            }
        }
    }
</script>

<style>

</style>