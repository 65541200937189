<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <p v-if="description">{{ description }}</p>
        <textarea class="form-control"
                  rows="10"
                  v-bind:placeholder="title"
                  v-bind:name="name"
                  v-bind:class="{'is-invalid': hasError}"
                  v-model="value"
        ></textarea>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin]

    }
</script>
