<template>
    <destroy-view type="Sponsor" backRoute="sponsors.index" :apiUrl="apiUrl"></destroy-view>
</template>

<script>
    import DestroyComponent from '../layouts/DestoryComponent.vue'

    export default {
        components : {
            'destroy-view' : DestroyComponent
        },
        props : {
            sponsorId : {
                required : true
            }
        },
        computed : {
            apiUrl : function () {
                return '/sponsors/' + this.sponsorId
            }
        }
    }
</script>

<style>

</style>