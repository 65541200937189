<template>
    <div class="form-group">
        <label class="control-label">{{ title }}</label>
        <div style="margin-top: 4px;">
            <div class="switch-button warning switch-button-yesno">
                <input type="checkbox"
                       v-bind:name="name"
                       v-model="value"
                       v-bind:class="{'is-invalid': hasError}"
                       :id="name"
                >
                <span>
            <label :for="name"></label>
            </span>
            </div>
        </div>
        <ul v-show="hasError" class="parsley-errors-list filled"><li class="parsley-required">{{ error }}</li></ul>
    </div>
</template>

<script>
    import {BasicFieldMixin} from '../../mixins/BasicFieldMixin.js'

    export default {
        mixins : [BasicFieldMixin]
    }
</script>
