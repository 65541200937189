<template>
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-success">
                    <div class="tools">
                        <router-link v-if="$permissioner.can('create helps')" :to="{name : 'helps.create'}"><span class="icon s7-plus"></span></router-link>
                    </div>
                    <span class="title">Handleiding</span>
                </div>
                <div class="panel-body">
                    <table class="no-border no-strip skills">
                        <tbody class="no-border-x no-border-y">
                        <tr v-for="help in helps">
                            <td class="icon"><span class="icon" :class="'s7-' + help.icon"></span></td>
                            <td class="icon">
                                <a v-show="help.order !== 1" href="#" @click.prevent="moveUp(help.id)"><span class="s7-angle-up"></span></a>
                            </td>
                            <td class="icon">
                                <a v-show="help.order !== helps.length" href="#" @click.prevent="moveDown(help.id)"><span class="s7-angle-down"></span></a>
                            </td>
                            <td class="item">
                                <router-link :to="{name : 'helps.show', params : {helpId : help.id}}">{{ help.title }}</router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel panel-default">
                <div class="panel-heading panel-heading-divider panel-heading-full-width">
                    <div class="tools">
                        <router-link v-if="$permissioner.can('update helps')" :to="{name : 'helps.edit', params : {helpId : id}}"><span class="icon s7-pen"></span></router-link>
                        <router-link v-if="$permissioner.can('delete helps')"  :to="{name : 'helps.delete', params : {helpId : id}}"><span class="icon s7-trash"></span></router-link>
                    </div>
                    <span class="title">{{ title }}</span>
                </div>
                <div class="panel-body help-content" v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                helps: [],
                title : '',
                content : '',
                id : 0,
            }
        },
        props : {
            helpId : {
                required: false,
                default : null,
            }
        },
        created(){
            this.loadHelps()
        },
        watch : {
            '$route' (to, from) {
                this.loadHelp(this.helpId)
            }
        },
        methods : {
            loadHelp : function(id){
                axios.get('/helps/' + id)
                    .then(response => {
                        let data = response.data.data

                        this.title = data.title;
                        this.content = data.content;
                        this.id = data.id
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            },
            loadHelps : function(){
                axios.get('/helps')
                    .then(response => {
                        this.helps = response.data.data;

                        if(this.helps.length === 0){
                            return;
                        }

                        this.helpId !== null ?
                            this.loadHelp(this.helpId) :
                            this.loadHelp(this.helps[0].id)
                    })
                    .catch(error => {
                        this.$notifier.error()
                    })
            },
            moveUp : function(id){
                axios.get('/helps/' + id + '/moveUp').catch(error => {
                    this.$notifier.error()
                })

                this.loadHelps()
            },
            moveDown : function(id){
                axios.get('/helps/' + id + '/moveDown').catch(error => {
                    this.$notifier.error()
                })

                this.loadHelps()
            },
        },
    }
</script>

<style>
    .help-content img{
        max-width: 100%;
        height: auto;
    }

    .help-content blockquote {
        display: block;
        border-width: 2px 0;
        border-style: solid;
        border-color: #eee;
        padding: 1.5em 0 0.5em;
        margin: 1.5em 0;
        position: relative;
    }
    .help-content blockquote:before {
        content: '\201C';
        position: absolute;
        top: 0em;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: 3rem;
        height: 2rem;
        font: 6em/1.08em 'PT Sans', sans-serif;
        color: #666;
        text-align: center;
    }
    .help-content blockquote:after {
        content: "\2013 \2003" attr(cite);
        display: block;
        text-align: right;
        font-size: 0.875em;
        color: #e74c3c;
    }

    .help-content img {
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 -1px 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
        -moz-box-shadow: inset 0 0 2px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 -1px 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
        box-shadow: inset 0 0 2px rgba(0,0,0,.7), inset 0 2px 0 rgba(255,255,255,.3), inset 0 -1px 0 rgba(0,0,0,.5), 0 1px 3px rgba(0,0,0,.4);
        margin-bottom: 10px;
        margin-top: 3px;
    }

    .help-content h2{
        font-size: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
    }
</style>