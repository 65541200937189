<template>
    <former :config="config">
        <field-hidden name="id"></field-hidden>
        <div class="row">
            <div class="col-md-6">
                <field-string title="Voornaam" name="firstname"></field-string>
            </div>
            <div class="col-md-6">
                <field-string title="Achternaam" name="lastname"></field-string>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <field-email title="Email" name="email"></field-email>
            </div>
            <div class="col-md-6">
                <field-string title="Telefoonnummer" name="phone"></field-string>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <field-string title="Straat + Nr." name="street"></field-string>
            </div>
            <div class="col-md-2">
                <field-string title="Postcode" name="zipcode"></field-string>
            </div>
            <div class="col-md-4">
                <field-string title="Stad" name="city"></field-string>
            </div>
        </div>
        <h4>Wachtwoord</h4>
        <p><i>Enkel invullen indien u dit wilt wijzigen</i></p>
        <div class="row">
            <div class="col-md-6">
                <field-password title="Wachtwoord" name="password"></field-password>
            </div>
            <div class="col-md-6">
                <field-password title="Wachtwoord(opnieuw)" name="password_confirmation"></field-password>
            </div>
        </div>
    </former>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    submitText: 'Wijzigen',
                    messageSuccessTitle: 'Gegevens Gewijzigd!',
                    updateUrl : '/users/updateAccount',
                    dataUrl : '/users/account',
                    update : true,
                },
            }
        },
    }
</script>

<style>

</style>