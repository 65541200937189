<template>
    <div class="row">
        <div class="col-md-12" v-for="sponsor in sponsors">
            <div class="panel">
                <div class="panel-heading panel-heading-color panel-heading-color-success">
                    <span class="title">{{ sponsor.name }}</span>
                    <div class="tools">
                        <router-link class="icon" :to="{ name: 'sponsors.show', params: { sponsorId: sponsor.id }}"><i class="s7-look"></i></router-link>
                        <router-link class="icon" :to="{ name: 'sponsors.edit', params: { sponsorId: sponsor.id }}"><i class="s7-pen"></i></router-link>
                        <router-link class="icon" :to="{ name: 'sponsors.delete', params: { sponsorId: sponsor.id }}"><i class="s7-trash"></i></router-link>
                    </div>
                </div>
                <div class="panel-body">
                    <p><strong>Leesgroepen</strong></p>
                    <div class="row" style="margin-bottom: 10px;">
                        <div v-for="group in sponsor.groups" class="col-md-4">
                            <router-link :to="{name: 'groups.show', params : {groupId:  group.id}}">{{ group.name }}</router-link>
                        </div>
                        <div v-if="sponsor.groups.length === 0" class="col-md-12">Geen</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sponsors : [],
            }
        },
        watch : {
            '$route' (newRoute, oldRoute){
                this.loadSponsors()
            }
        },
        methods : {
            loadSponsors : function(){
                axios.get('/sponsors?include=groups')
                    .then(response => {
                        this.sponsors = response.data.data
                    })
                    .catch(error => {
                        this.$notifier.error()
                    });
            },

        },
        created(){
            this.loadSponsors();
        }
    }
</script>

<style>

</style>