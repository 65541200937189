<template>
    <destroy-view type="Participant" :apiUrl="apiUrl"></destroy-view>
</template>

<script>
    import DestroyComponent from '../layouts/DestoryComponent.vue'

    export default {
        components : {
            'destroy-view' : DestroyComponent
        },
        props : {
            groupId : {
                required : true
            },
            periodId : {
                required : true
            },
            participantId : {
                required : true
            }
        },
        computed : {
            apiUrl : function () {
                return '/groups/' + this.groupId + '/periods/' + this.periodId + '/participants/' + this.participantId
            }
        }
    }
</script>

<style>

</style>