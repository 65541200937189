<template>
    <div>
        <div class="row" style="margin-bottom: 10px;">
            <div class="col-sm-12">
                <pdf :src="src"
                     :page="currentPage"
                     :rotate="rotate"
                     @num-pages="pageCount = $event"
                     ></pdf>
            </div>
            <div class="col-sm-12">
                <hr />
            </div>
        </div>
        <div class="row">
            <div class="col align-self-center">
                <p class="text-muted">{{currentPage}} van {{pageCount}}</p>
            </div>
            <div class="col align-self-center">
                <div class="btn-toolbar" style="float: right">
                    <div class="btn-group btn-space">
                        <a :href="src" class="btn btn-dark" download>
                            <i class="icon icon-left s7-download"></i> Download
                        </a>
                        <button type="button" class="btn btn-dark" @click="rotatePage">
                            <i class="icon s7-refresh"></i>
                        </button>
                    </div>
                    <div class="btn-group btn-space">
                        <button type="button" class="btn btn-dark" @click="prevPage">
                            <i class="icon s7-angle-left"></i>
                        </button>
                        <button type="button" class="btn btn-dark" @click="nextPage">
                            <i class="icon s7-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import pdf from 'vue-pdf'

    export default {
        components: {
            pdf
        },
        props : {
            src : {
                required : true,
                type: String,
            }
        },
        data() {
            return {
                currentPage: 1,
                pageCount: 0,
                rotate: 0,
            }
        },
        methods : {
            prevPage : function () {
                if(this.currentPage !== 1){
                    this.currentPage--;
                }
            },
            nextPage : function(){
                if(this.currentPage !== this.pageCount){
                    this.currentPage++;
                }
            },
            rotatePage : function () {
                if(this.rotate === 270){
                    this.rotate = 0;
                }else{
                    this.rotate += 90;
                }
            }
        }
    }
</script>

<style>

</style>